import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SidebarContext } from "Context/SidebarContext";
import useScrollPosition from "@react-hook/window-scroll";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Dropdown from "react-bootstrap/Dropdown";
import NotficationIcon from "assets/images/icons/notification-bing.svg";
import MoneyIcon from "assets/images/icons/moneys.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/auth/actions";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import useAxiosFetch from "hooks/useAxiosFetch";
import { useMemo } from "react";
import { DateTime } from "luxon";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import swLogo from "assets/images/logo/sw-logo.png";
import toast from "react-hot-toast";
import API from "helpers/api";
export const Header = () => {
  const { mobileSidebarOpen, setMobileSidebarOpen, sidebarwidth } =
    useContext(SidebarContext);
  const { user } = useSelector((state) => state.auth);
  const scrollY = useScrollPosition(60);
  const [navbarBG, setNavbarBG] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const {
    data: { notifications = [] } = {},
    updateData,
    mutate: getNotifications,
  } = useAxiosFetch("/notifications", {
    method: "GET",
    lazy: true,
    functions: {
      onSuccess: ({data}) => {
        console.log(data);
        const prevCount = JSON.parse(localStorage.getItem("notificationsCounts"));
        if(prevCount) {
          if (data?.notifications?.length > prevCount?.[user.id] || 0) {
            localStorage.setItem(
              "notificationsCounts",
              JSON.stringify({
                ...prevCount,
                [user.id]: data?.notifications?.filter(e=> !e.isRead)?.length,
              })
            );
          } else {
            localStorage.setItem(
              "notificationsCounts",
              JSON.stringify({
                ...prevCount,
                [user.id]: data?.notifications?.length,
              })
            );
          }
        } else {
          localStorage.setItem(
            "notificationsCounts",
            JSON.stringify({
              [user.id]: data?.notifications?.length,
            })
          );
        }
      },
    },
  });
  const lastNotificationCount = useMemo(() => {
    return JSON.parse(localStorage.getItem("notificationsCounts"))?.[user.id] || 0;
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    setNavbarBG(scrollY > 60);
  }, [scrollY]);

  const handleSidebarToggle = () => {
    setMobileSidebarOpen(!mobileSidebarOpen);
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const handleClose = () => setAnchorEl(null);
  const unreadNotificationsLength =
    (notifications.length > 0 ? notifications : user.notifications)?.filter(
      (notification) => !notification.isRead
    )?.length || 0;
  const allNotificationsLength = useMemo(() => {
    return notifications.filter(e=> !e.isRead)?.length || user.notifications.filter(e=> !e.isRead)?.length || 0;
  }, [notifications?.length, user.notifications?.length]);
  const [openPopover, setOpenPopover] = useState(false);
  useEffect(() => {
    if (openPopover) {
      const t = setTimeout(() => {
        setOpenPopover(false);
      }, 10000);
      return () => clearTimeout(t);
    }
  }, [openPopover]);
  useEffect(() => {
    if (allNotificationsLength - lastNotificationCount > 0) {
      setOpenPopover(true);
    }
  }, [allNotificationsLength, lastNotificationCount]);
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${sidebarwidth}px)` },
        ml: { md: `${sidebarwidth}px` },
        background: navbarBG === true ? "white" : "transparent",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "end", gap: "16px" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleSidebarToggle}
          sx={{ mr: "auto", color: "black", display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Link
          to="/user/credits"
          className="bg-secondary rounded-pill d-flex gap-3 align-items-center h-40 px-4 text-decoration-none"
        >
          <img src={MoneyIcon} alt="Money Icon" width={"20px"} />
          <span className="fs-14px text-white">{user?.credits}</span>
        </Link>
        <Tooltip
          title={<Typography variant="body2">
            You have {
              allNotificationsLength - lastNotificationCount
            } new notifications
          </Typography>}
          open={openPopover}
          placement="bottom-end"
          arrow
        >
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              getNotifications();
            }}
            className="bg-white w-40 h-40 rounded-circle d-flex justify-content-center align-items-center position-relative cursor-pointer"
          >
            <img
              src={NotficationIcon}
              alt="Notifvation Icon"
              width={"24px"}
              height="auto"
              className="img-fluid obj-fit-cover obj-position-center "
            />
            <div
              className="position-absolute text-white bg-secondary rounded-circle ratio-1x1 d-flex justify-content-center align-content-center fs-10px"
              style={{
                padding: ".25rem",
                top: "3px",
                right: "5px",
                width: 20,
                height: 20,
                lineHeight: `12.5px`,
              }}
            >
              {unreadNotificationsLength < 100
                ? unreadNotificationsLength < 10
                  ? `0${unreadNotificationsLength}`
                  : unreadNotificationsLength
                : "99+"}
            </div>
          </IconButton>
        </Tooltip>
        <Popover
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          PaperProps={{
            "aria-labelledby": "basic-button",
            style: {
              width: 350,
              maxHeight: 450,
            },
          }}
        >
          <List className="py-0 h-100">
            <ListSubheader className="shadow-sm mb-3 py-2">
              <div className="d-flex justify-content-between align-items-center">
                <span className="h6 fw-bold mb-0">Notifications</span>
                <a
                  onClick={async (e) => {
                    e.preventDefault();
                    if (notifications?.find((n) => !n.isRead)) {
                      await updateData(() => API.post("/notifications/read"), {
                        onSuccess: () => {
                          toast.success("Notifications marked as read");
                        },
                      });
                    } else {
                      toast("You are all set!", {
                        icon: "👏",
                        style: {
                          borderRadius: "10px",
                          background: "#333",
                          color: "#fff",
                        },
                      });
                    }
                  }}
                  href="/"
                  className="fs-12px text-decoration-none text-secondary d-inline-flex align-items-center"
                >
                  <DoneAllIcon fontSize="small" className="me-2" /> Mark all as
                  read
                </a>
              </div>
            </ListSubheader>
            {notifications.length > 0 ? (
              notifications?.map((notification, index) => (
                <ListItemButton
                  className="mx-3 mb-2 rounded-3"
                  dense
                  sx={{
                    backgroundColor: !notification?.isRead
                      ? "rgba(0,0,0,0.05)"
                      : "transparent",
                    "& .span": {
                      color: !notification?.isRead ? "primary.main" : "gray",
                    },
                    "&:hover .span": {
                      color: "primary.main",
                    },
                  }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: 20,
                    }}
                  >
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor:
                          notification?.isRead === false
                            ? "primary.main"
                            : "primary.light",
                      }}
                    ></Box>
                  </ListItemIcon>
                  <ListItem
                    className="ps-0"
                    dense
                    key={index}
                    onClick={handleClose}
                  >
                    <ListItemText
                      primary={
                        <span className="span d-inline-block pe-4">
                          {notification?.notification.body}
                        </span>
                      }
                      secondary={
                        <div className="d-flex justify-content-between">
                          <span className="fs-12px">
                            {DateTime.fromISO(
                              notification?.notification.createdAt
                            ).toFormat("LLL dd, yyyy hh:mm a")}
                          </span>
                        </div>
                      }
                    />
                  </ListItem>
                  <ListItemSecondaryAction>
                    <Avatar
                      alt="Remy Sharp"
                      className="p-1 border bg-white"
                      src={swLogo}
                    />
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))
            ) : (
              <div className="d-flex py-5 justify-content-center align-items-center h-100">
                <span className="">You are all set!</span>
              </div>
            )}
            <ListSubheader
              sx={{
                top: "auto",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                  cursor: "pointer",
                },
              }}
              className="shadow-sm bottom-0 mt-3"
              onClick={() => {
                if (notifications?.length) {
                  updateData(() => API.delete("/notifications"), {
                    onSuccess: () => {
                      toast.success("Notifications Deleted");
                    },
                  });
                } else {
                  toast("You are all set!", {
                    icon: "👏",
                    style: {
                      borderRadius: "10px",
                      background: "#333",
                      color: "#fff",
                    },
                  });
                }
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <DeleteIcon fontSize="small" className="me-2" />
                <span className="fs-12px">Clear all</span>
              </div>
            </ListSubheader>
          </List>
        </Popover>
        <Dropdown align="end">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="bg-transparent border-0 shadow-none p-0"
          >
            <Avatar
              src={user?.avatar}
              alt="profile Img"
              width={"!00%"}
              height="100%"
              className="img-fluid obj-fit-cover obj-position-center ratio-1x1 w-40 h-40 rounded-circle border border-white border-3"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as="div">
              <Link
                to={`/${user.role}/account`}
                className="d-block text-decoration-none"
              >
                User Profle
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="div" onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Toolbar>
    </AppBar>
  );
};
