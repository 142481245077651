import React from "react";
import arrow from "assets/images/icons/arrow-circle.svg";
import { Link } from "react-router-dom";

export const TextIconButton = (props) => {
  return (
    <Link
      to={props.url}
      {...props}
      className="d-inline-flex gap-0 fs-14px fw-normal text-dark text-decoration-none text-capitalize"
    >
      <span className="border-bottom border-2 border-dark pe-3 d-flex align-items-center fs-12px">
        {props.text}
      </span>
      <span
        className={`${
          props.className ? props.className : "bg-secondary"
        } text-white rounded-pill d-inline-block px-1 py-0`}
      >
        <img
          src={arrow}
          alt="Arrow"
          width="24px"
          height="auto"
          className="img-fluid obj-fit-cover obj-position-center"
        />
      </span>
    </Link>
  );
};
