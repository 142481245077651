import * as constants from './constants';
import * as service from './service';
import { wrapper } from ".."
import toast from "react-hot-toast";

const getWineries = (page="all",functions) => async (dispatch) => {
  try {
    if(page=== "all") {
      dispatch({ type: constants.GET_WINERIES_REQUEST });
      const response = await wrapper(functions, service.getWineries);
      dispatch({
        type: constants.GET_WINERIES_SUCCESS,
        payload: {
          ...response.data,
        },
      });
    } else {
      dispatch({ type: constants.GET_PAGINATED_WINERIES_REQUEST });
      const response = await wrapper(functions, service.getPaginatedWineries, page);
      dispatch({
        type: constants.GET_PAGINATED_WINERIES_SUCCESS,
        payload: {
          ...response.data,
          page,
        },
      });
    }
  } catch (error) {
    dispatch({ type: constants.GET_WINERIES_FAILURE, payload: error });
  }
}

const getWinery = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.GET_WINERY_REQUEST });
  try {
    const response = await wrapper(functions, service.getWinery, data);
    dispatch({
      type: constants.GET_WINERY_SUCCESS,
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    dispatch({ type: constants.GET_WINERY_FAILURE, payload: error });
  }
}

const addWinery = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.ADD_WINERY_REQUEST });
  try {
    const response = await wrapper(functions, service.addWinery, data);
    dispatch({
      type: constants.ADD_WINERY_SUCCESS,
      payload: {
        ...response.data,
      },
    });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: constants.ADD_WINERY_FAILURE, payload: error });
  }
};

const updateWinery = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.UPDATE_WINERY_REQUEST });
  try {
    const response = await wrapper(functions, service.updateWinery, data);
    dispatch({
      type: constants.UPDATE_WINERY_SUCCESS,
      payload: {
        ...response.data,
      },
    });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: constants.UPDATE_WINERY_FAILURE, payload: error });
  }
}

const deleteWinery = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.DELETE_WINERY_REQUEST });
  try {
    const response = await wrapper(functions, service.deleteWinery, data);
    dispatch({
      type: constants.DELETE_WINERY_SUCCESS,
      payload: {
        ...response.data,
      },
    });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: constants.DELETE_WINERY_FAILURE, payload: error });
  }
}

const getFeaturedWineries = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.GET_FEATURED_WINERIES_REQUEST });
  try {
    const response = await wrapper(functions, service.getFeaturedWineries, data);
    dispatch({
      type: constants.GET_FEATURED_WINERIES_SUCCESS,
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    dispatch({ type: constants.GET_FEATURED_WINERIES_FAILURE, payload: error });
  }
}

const getWineriesByCountry = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.GET_WINERIES_BY_COUNTRY_REQUEST });
  try {
    const response = await wrapper(functions, service.getWineriesByCountry, data);
    dispatch({
      type: constants.GET_WINERIES_BY_COUNTRY_SUCCESS,
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    dispatch({ type: constants.GET_WINERIES_BY_COUNTRY_FAILURE, payload: error });
  }
};

const removeImage = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.REMOVE_IMAGE_REQUEST });
  try {
    const response = await wrapper(functions, service.removeImage, data);
    dispatch({
      type: constants.REMOVE_IMAGE_SUCCESS,
      payload: {
        ...response.data.winery,
      },
    });
  } catch (error) {
    dispatch({ type: constants.REMOVE_IMAGE_FAILURE, payload: error });
  }
}

const getHistoricVisits = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.GET_HISTORIC_VISITS_REQUEST });
  try {
    const response = await wrapper(functions, service.getHistoricVisits, data);
    dispatch({
      type: constants.GET_HISTORIC_VISITS_SUCCESS,
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    dispatch({ type: constants.GET_HISTORIC_VISITS_FAILURE, payload: error });
  }
}

export {
  getWineries,
  getWinery,
  addWinery,
  updateWinery,
  deleteWinery,
  getFeaturedWineries,
  getWineriesByCountry,
  removeImage,
  getHistoricVisits,
}