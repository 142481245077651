import React from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from 'react';

const MyEditor = ({ onChange, value="", defaultValue, ...props }) => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  useEffect(()=> {
    if(defaultValue) {
      const contentBlock = htmlToDraft(defaultValue);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  },[defaultValue])
  return (
    <div className='border'>
      <Editor
        wrapperClassName="demo-wrapper"
        editorClassName="px-3"
        editorState={editorState}
        onEditorStateChange={setEditorState}
        onChange={(content)=> onChange(draftToHtml(content))}
      />
      <textarea
        disabled
        hidden
        {...props}
        value={value}
      />
    </div>
  );
}

export default MyEditor;