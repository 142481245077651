import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, IconButton } from "@mui/material";

const Accordion = ({ question, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="bg-white shadow rounded-3 mb-3">
      <div
        style={{ cursor: "pointer" }}
        onClick={() => setExpanded(!expanded)}
        className="pt-3 pb-2 px-4 d-flex justify-content-between align-items-center fw-bold"
      >
        <div className="h6 mb-0">{question}</div>
        <IconButton>
          <ExpandMoreIcon
            style={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
            sx={{ transition: "transform 0.3s ease-in-out" }}
          />
        </IconButton>
      </div>
      <div className="pb-2">
        <Collapse in={expanded}>
          <div
            className="px-4 pt-2 pb-2"
            style={{ borderTop: `1px solid #ececec` }}
          >
            <p className="mb-0">{children}</p>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Accordion;
