import React, { useEffect } from "react";
import { WebRTCClient } from "@arcware/webrtc-plugin";
import { useRef } from "react";
import toast from "react-hot-toast";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";

const StreamPopup = ({ handleClose, id }) => {
  const sizeContainerRef = useRef();
  const containerRef = useRef();
  /**
   * @type {React.Ref<WebRTCClient>}
   */
  const webrtc_client = useRef();
  useEffect(() => {
    try {
      console.log("useClassicEffect");
      if (id) {
        /**
         * @type {import("@arcware/webrtc-plugin").WebRTCClientProps}
         */
        const args = {
          address: "wss://signalling-client.ragnarok.arcware.cloud/",
          packageId: id,
          settings: {},
          playOverlay: true,
          container: containerRef.current,
          sizeContainer: sizeContainerRef.current,
          videoInitializeCallback: (video) => {
            console.log("videoInitializeCallback", video);
          },
          applicationResponse: (data) => {
            console.log(data);
          },
        };
        console.log("args", args);
        if (!webrtc_client.current) webrtc_client.current = new WebRTCClient(args);
        console.log("webrtc_client.current", webrtc_client.current);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error loading stream. Please try again later.");
    }
    return () => {
      if (id) webrtc_client.current?.closeWebsocket("Popup closed");
    };
  }, [id]);
  return (
    <Modal
      style={{ zIndex: 10000 }}
      fullWidth
      show={!!id}
      onHide={handleClose}
      onEscapeKeyDown={handleClose}
      onBackdropClick={handleClose}
      dialogClassName="mw-100 mh-100 w-100 m-0"
    >
      <Modal.Body className="p-0 m-0 vh-100 w-100 mw-100 mh-100">
        <div
          onClick={handleClose}
          className="w-100 bg-black mx-auto h-100 position-relative"
          ref={sizeContainerRef}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="mx-auto"
            ref={containerRef}
          >
            <Loader />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StreamPopup;
