import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CreditPackage } from "Components/CreditPackage";
import { TextIconButton } from "Components/TextIconButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { makePayment } from "store/auth/actions";
import MoneyIcon from "assets/images/icons/moneys.svg";
import { Link } from "react-router-dom";
const creditPackageArr = [
  {
    price: "4,5 €",
    creditNumber: 5,
    tagLine: "one time payment 23 €",
    points: [
      "Access to visit wineries from 1 credit",
      "Purchase your exclusive NFT bottle from 1 credit",
      "Your own digital cellar",
      "Access for free to digital events organized by wineries",
    ],
    save: "10%",
    url: "/",
    recomemded: false,
    name: "REACT_APP_FIVE_CREDITS_KEY",
    color: "secondary",
  },
  {
    price: "4,0 €",
    creditNumber: 10,
    tagLine: "one time payment 40 €",
    points: [
      "Access to visit wineries from 1 credit",
      "Purchase your exclusive NFT bottle from 1 credit",
      "Your own digital cellar",
      "Access for free to digital events organized by wineries",
    ],
    save: "20%",
    url: "/",
    recomemded: false,
    name: "REACT_APP_TEN_CREDITS_KEY",
    color: "green",
  },
  {
    price: "3,5 €",
    creditNumber: 15,
    tagLine: "one time payment 53 €",
    points: [
      "Access to visit wineries from 1 credit",
      "Purchase your exclusive NFT bottle from 1 credit",
      "Your own digital cellar",
      "Access for free to digital events organized by wineries",
    ],
    save: "30%",
    url: "/",
    recomemded: true,
    name: "REACT_APP_FIFTEEN_CREDITS_KEY",
    color: "blue",
  },
];

export const Credits = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  return (
    <Elements stripe={stripePromise}>
      <InnerCredits />
    </Elements>
  );
};

const InnerCredits = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="p-4 rounded-12 bg-white">
      <h4>Select a Credit Package</h4>
      <p className="col-sm-12 fs-14px">
        The credits are used to visit wineries, buy your pack and save up to 30%
        with our recommended "<b>Pack of 15 credits</b>".
      </p>

      <Row className="g-4 align-items-end mb-5">
        {creditPackageArr.map((pkg, index) => {
          return (
            <Col sm="6" xl="4" key={index} className="p-3">
              <CreditPackage {...pkg} />
            </Col>
          );
        })}
      </Row>

      <section>
        <Row>
          <Col lg="6">
            <section className="border p-3 rounded-16">
              <p>
                Or buy only{" "}
                <TextIconButton
                  url={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      makePayment({
                        name: process.env["REACT_APP_ONE_CREDIT_KEY"],
                        quantity: 1,
                        success_url: `${window.location.origin}/user`,
                        cancel_url: `${window.location.origin}/user`,
                      })
                    );
                  }}
                  text={[
                    <span className="fw-bold me-1" key={"1"}>
                      1 credit
                    </span>,
                    " for 5 €",
                  ]}
                  className="bg-dark"
                />
              </p>
              <p className="fs-12px text-muted">
                You could start to visit a winery from 1 credit.
              </p>
            </section>
          </Col>
          <Col lg="6" className="d-flex justify-content-center align-items-center">
            <section>
              <div className="px-5 bg-light d-flex rounded-pill align-items-center py-3">
                Your Current Credit Balance:{" "}
                <Link
                  to="/user/credits"
                  className="bg-secondary ms-4 rounded-pill d-flex gap-3 align-items-center h-40 px-4 text-decoration-none"
                >
                  <img src={MoneyIcon} alt="Money Icon" width={"20px"} />
                  <span className="fs-14px text-white">{user?.credits}</span>
                </Link>
              </div>
            </section>
          </Col>
        </Row>
      </section>
    </div>
  );
};
