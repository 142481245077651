import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader = ({
  fullPage = false,
}) => {
  return (
    <div className={fullPage ? 'vh-100' : 'py-5'}>
      <CircularProgress />
    </div>
  );
};

export default Loader;