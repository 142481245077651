import toast from "react-hot-toast";
import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userReducer } from "./auth/reducer";
import wineries from "./wineries/reducer";
const auth = persistReducer(
  {
    key: "auth",
    storage,
  },
  userReducer
);
const middleware = [thunkMiddleware];
const store = configureStore({
  reducer: { auth, wineries },
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

const wrapper = async (functions, api, data = {}) => {
  try {
    functions?.onRequest?.();
    const response = await api(data);
    functions?.onSuccess?.(response);
    return response;
  } catch (error) {
    console.log(error);
    toast.dismiss()
    toast.error(error?.response?.data?.message || error?.message || "Something went wrong");
    functions?.onFailure?.(error);
    throw error;
  }
};
export { store, persistor, wrapper };
