import React from "react";
import locationIcon from "assets/images/icons/location.png";
import worldIcon from "assets/images/icons/world.svg";
import { TextIconButton } from "Components/TextIconButton";
import { useNavigate } from "react-router-dom";

export const FeatureProduct = (winery) => {
  const navigate = useNavigate();
  return (
    <section className="w-100 h-100 overflow-hidden rounded-8 border bg-white">
      <div className="position-relative">
        <div style={{cursor: "pointer"}} onClick={()=> {
          navigate(`/winery/${winery._id}`)
        }}>
          <img
            src={winery.images?.[0]}
            alt={winery.name}
            loading="lazy"
            width={"100%"}
            style={{aspectRatio: "3/2"}}
            className="obj-fit-cover d-flex text-center justify-content-center align-items-center obj-position-center"
          />
        </div>
        {winery.comingSoon ? (
          <div className="d-inline-block fs-14px bg-white position-absolute top-24 end-0 px-3 py-1 rounded-pill rounded-end-0">
            Coming Soon
          </div>
        ) : winery.isFeatured ? (
          <div className="d-inline-block fs-14px bg-white position-absolute top-24 end-0 px-3 py-1 rounded-pill rounded-end-0">
            Featured
          </div>
        ) : null}
      </div>
      <div className="p-3 pb-4">
        <div className="text-decoration-none text-black d-inline-block">
          <p className="fw-medium mb-2">{winery.name}</p>
        </div>
        {winery.region && (
          <p className="fs-12px mb-2">
            <img
              src={locationIcon}
              loading="lazy"
              alt="Location"
              width={"16px"}
              height={"16px"}
              className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
            />
            {winery.region}
          </p>
        )}

        {winery.country && (
          <p className="fs-12px">
            <img
              src={worldIcon}
              loading="lazy"
              alt="world"
              width={"16px"}
              height={"16px"}
              className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
            />
            {winery.country}
          </p>
        )}

        {winery.logo && (
          <div className="mb-4 text-center">
           <div style={{cursor: "pointer"}} onClick={()=> {
          navigate(`/winery/${winery._id}`);
        }}>
            <img
              src={winery.logo}
              loading="lazy"
              alt="wine logo"
              width={"100%"}
              height="100%"
              style={{
                aspectRatio: "2",
                maxWidth: "160px",
                objectFit: "scale-down"
              }}
              className="img-fluid obj-fit-contain obj-position-center"
            />
          </div>
          </div>
        )}
       {/* <TextIconButton onClick={(e)=> {
        }} url={`/winery/${winery._id}`} text="Start your visit" /> */}
      </div>
    </section>
  );
};
