import React from "react";
import { Button } from "react-bootstrap";
import moneyIcon from "assets/images/icons/moneys.svg";
export const MoneyButton = ({ value, number, ...props }) => {
  return (
    <Button {...props} className={`ps-3 ${props.className}`}>
      <div className="d-flex align-items-center gap-1">
        <img
          src={moneyIcon}
          alt="Money Icon"
          width={"24px"}
          height={"auto"}
          className="img-fluid object-fit-cover obj-position-center me-1"
        />
        {number}
        <span className="mx-3">{value}</span>
      </div>
    </Button>
  );
};
