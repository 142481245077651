import React from "react";
import { MoneyButton } from "Components/MoneyButton";
export const NftProduct = (product) => {
  return (
    <div className={`bg-white d-flex justify-content-between flex-column p-3 h-100 rounded-8 mx-auto`}>
      <div>
      <div className="d-flex align-items-center justify-content-center mb-3">
        <img
          src={product.image}
          alt="Product Img"
          loading="lazy"
          width={"100%"}
          style={{aspectRatio: "1/1", objectFit: "contain", objectPosition: "center"}}
          className="img-fluid obj-position-center"
        />
      </div>
      <h5 className="text-start mb-3">{product.title || "No Title Available"}</h5>
      {product.description && <p dangerouslySetInnerHTML={{__html: product.description || "No Description"}}></p>}
      </div>
      <div className="text-center">
        <MoneyButton
          value="Collect NFT"
          className="btn-blue rounded-pill py-2 text-capitalize"
          onClick={() => {
            window.open(product.link, "_blank");
          }}
        />
      </div>
    </div>
  );
};
