import React, { createContext, useState } from "react";
export const SidebarContext = createContext();
const sidebarwidth = 300;
export const SidebarContextProvider = (props) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  return (
    <SidebarContext.Provider
      value={{ mobileSidebarOpen, setMobileSidebarOpen, sidebarwidth }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
};
