import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SecondWinery from "assets/images/logo/secondwinery.png";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { Button } from "react-bootstrap";

export const Footer = () => {
  const socailLinkArr = [
    {
      title: "facebook",
      icon: FaFacebookF,
      url: "https://www.facebook.com/",
    },
    {
      title: "Twitter",
      icon: FaTwitter,
      url: "https://twitter.com/",
    },
    {
      title: "Linkedin",
      icon: FaLinkedinIn,
      url: "https://www.linkedin.com/",
    },
    {
      title: "Youtube",
      icon: AiOutlineYoutube,
      url: "https://www.youtube.com/",
    },
    {
      title: "Instagram",
      icon: FaInstagram,
      url: "https://www.instagram.com/",
    },
  ];
  const handleScroll = (scrolling) => {
    window.scroll({
      top:
        scrolling === "top"
          ? 0
          : document.body.offsetHeight || document.documentElement.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer className="bg-primary">
      <Container>
        <Row className="py-5 justify-content-between g-4">
          <Col xs="12">
            <Link to="/" className="mb-4 d-inline-block">
              <img
                src={SecondWinery}
                loading="lazy"
                alt="Logo"
                className="img-fluid obj-fit-cover obj-position-center mw-200px"
              />
            </Link>
          </Col>
          <Col lg="5">
            <div>
              <p className="text-light fs-14px">
                Enjoy every digital twin of the most famous wineries worldwide
                Second Winery's vision is to turn the typical physical visit to
                a winery into a virtual and immersive 3D experience with high
                quality.
              </p>
              <ul type="none" className="p-0">
                <li>
                  <a
                    href="https://secondwinery.com/privacy"
                    className="text-light text-decoration-none lh-lg  fs-14px"
                  >
                    -Privacy and Cookie Policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://secondwinery.com/privacy"
                    className="text-light text-decoration-none lh-lg fs-14px"
                  >
                    -Terms and Conditions
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md="6" lg="3">
            <h5 className="text-uppercase text-white mb-3">DISCOVER</h5>
            <ul type="none" className="p-0">
              <li>
                <Link
                  to="/about"
                  className="text-white text-decoration-none lh-lg text-capitalize fs-14px"
                  onClick={() => {
                    handleScroll("top");
                  }}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  className="text-white text-decoration-none lh-lg text-capitalize fs-14px p-0 shadow-none"
                >
                  Sign Up For Free
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-white text-decoration-none lh-lg text-capitalize fs-14px p-0 shadow-none"
                >
                  Login
                </Link>
              </li>
              <li>
                <Button className="text-white text-decoration-none lh-lg text-capitalize fs-14px p-0 shadow-none">
                  Get In Touch
                </Button>
              </li>
            </ul>
          </Col>
          <Col md="6" lg="3">
            <h5 className="text-uppercase text-white mb-3">ADDRESS</h5>
            <address className="text-white mb-2  fs-14px">
              68 Beaumont Road <br /> London, UK
            </address>
            <p className="text-white m-0  fs-14px">
              E-Mail:{" "}
              <a
                href="mailto:contact@secondwinery.com"
                className="text-white text-decoration-none"
              >
                contact@secondwinery.com
              </a>
            </p>
          </Col>
        </Row>
        <hr className="m-0 bg-white" />
        <div className="d-flex flex-wrap justify-content-between align-items-center gap-4 py-4">
          <p className="m-0 text-white  fs-14px">
            Hand crafted with <span className="text-danger">♥</span> in London
          </p>
          <ul type="none" className="p-0 m-0 d-flex align-items-center gap-3">
            {socailLinkArr.map((socilLink) => {
              return (
                <li key={socilLink.title}>
                  <a
                    href={socilLink.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white bg-white-10 rounded-circle ratio-1x1 d-inline-flex justify-content-center align-items-center w-40"
                  >
                    <socilLink.icon />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </footer>
  );
};
