import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import { Header } from "Components/Header";
import { Sidebar } from "Components/Sidebar";
import { SidebarContext } from "Context/SidebarContext";

export const Layout = () => {
  const { sidebarwidth } = useContext(SidebarContext);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${sidebarwidth}px)` },
          background: "#f1f0f0",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
