import React from "react";
import { InputAdornment, MenuItem } from "@mui/material";
import searchImg from "assets/images/icons/search.svg";
import { countries } from "Api/country";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import manageUser from "assets/images/icons/user.svg";
import moneyIcon from "assets/images/icons/moneys-secondary.svg";

import { CustomTextFieldRounded, CustomTextField } from "utils/mui";
import { DateRangePicker } from "Components/DateRangePicker";
import useAxiosFetch from "hooks/useAxiosFetch";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import Input from "utils/Input";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { constants } from "store/auth/constants";
const XLSX = require("xlsx");

const creditsValidationSchema = yup.object().shape({
  email: yup.string().email("Invalid Format").required("Email is required"),
  credits: yup.string().required("Credits are required"),
});

const notificationValidationSchema = yup.object().shape({
  email: yup.string().email("Invalid Format"),
  country: yup.string(),
  all: yup.boolean(),
  body: yup.string().required("Message is required"),
});

export const ManageUsers = () => {
  const { data } = useAxiosFetch("/auth/active-users", { method: "GET" });
  const creditResolver = useYupValidationResolver(creditsValidationSchema);
  const notificationResolver = useYupValidationResolver(
    notificationValidationSchema
  );
  const dispatch = useDispatch();
  const { handleSubmit: handleCreditSubmit, control: creditControl } = useForm({ resolver: creditResolver });
  const { handleSubmit: handleNotificationSubmit, control: notificationControl, setValue,watch } = useForm({ resolver: notificationResolver });
  const { mutate: addCredits } = useAxiosFetch("/auth/add-credits", {
    method: "POST",
    lazy: true,
    functions: {
      onSuccess: () => {
        toast.success("Credits added successfully");
      }
    }
  });
  const all = watch("all");
  const country = watch("country");
  const email = watch("email");
  const { mutate: createNotification, loading } = useAxiosFetch("/notifications", {
    method: "POST",
    lazy: true,
    functions: {
      onSuccess: (response) => {
        toast.success("Notification created successfully");
        dispatch({ type: constants.UPDATE_USER_SUCCESS, payload: response.data.user })
      }
    }
  });
  const countryArray = useMemo(
    () => ({
      title: "Top 10 Countries by users",
      data: Object.entries(data?.groupUsersByCountry || {})?.map(
        ([country, users]) => ({
          countryName: country,
          users: `${users.length} users`,
          data: users.map(({ visits, id,_id, ...el})=> ({...el, "Total Visits": visits.length})),
        })
      ),
    }),
    [data]
  );

  const winaryArray = useMemo(
    () => ({
      title: "Top 10 wineries visited",
      data: Object.entries(data?.groupUsersByWineryVisits || {})?.map(
        ([winery, users]) => {
          return {
            wineryName: winery,
            visits: `${users.length} visits`,
            data: users.map(({ visits, id,_id, ...el})=> ({...el, "Total Visits": visits.length})),
          };
        }
      ),
    }),
    [data]
  );

  return (
    <section className="bg-white p-4 rounded-16">
      <form onSubmit={handleNotificationSubmit(({body, country,all,email})=> {
        if(all){
          createNotification({ body });
        } else if(country){
          createNotification({ body, country });
        } else if(email){
          createNotification({ body, email });
        } else {
          toast.error("Please select atleast one option");
        }
      })} action="/">
      <div className="d-flex flex-wrap align-items-center gap-4 mb-4">
        <h4 className="mb-0">Notifications </h4>
        <div className="d-flex align-items-center gap-2 flex-wrap">
          <Input
            sx={{ width: "150px" }}
            id="searchWinery"
            placeholder="Find User"
            disabled={all || country}
            name="email"
            control={notificationControl}
            TextInput={CustomTextFieldRounded}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={searchImg}
                    alt="Search Img"
                    width={"24px"}
                    height="24px"
                    className="img-fluid obj-fit-cover obj-position-center"
                  />
                </InputAdornment>
              ),
            }}
          />
          <p className="m-0">or</p>
          <Input
            sx={{ width: "250px" }}
            id="country"
            select
            label=" "
            name="country"
            defaultValue={""}
            SelectProps={{
              displayEmpty: true,
              disabled: all || email,
            }}
            disabled={all || email}
            control={notificationControl}
            TextInput={CustomTextFieldRounded}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {countries.map((country, index) => {
              return (
                <MenuItem value={country.text} key={index}>
                  <div
                    className={`fi fi-${country.id} me-2 rounded-circle border w-20 h-20`}
                  ></div>
                  {country.text}
                </MenuItem>
              );
            })}
          </Input>
          <p className="m-0">or</p>
          <Button
            variant={all ? "dark" : "link"}
            onClick={()=> {
              setValue("all", !all);
              if(!all) {
                setValue("country", "");
                setValue("email", "");
              }
            }}
            className="border rounded-pill px-5 text-decoration-none py-2 lh-lg"
          >
            Select All
          </Button>
        </div>
      </div>

      <section className="my-5">
        <Row className="justify-content-center">
          <Col lg="10">
            <p className="fs-14px mb-2">Message</p>
            <Row className="g-4">
              <Col xs="8" md="6" lg="8" xl="7">
                <Input
                  fullWidth
                  type={"text"}
                  label=" "
                  id="text"
                  placeholder="Enter here the text"
                  name="body"
                  control={notificationControl}
                  TextInput={CustomTextField}
                />
              </Col>
              <Col xs="4" md="5" lg="4">
                <Button disabled={loading} type="submit" variant="success" className="h-100 w-100 rounded-1">
                  Send notification
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      </form>
      <hr />

      <section>
        <div className="d-flex flex-wrap align-items-center gap-3">
          <h4>Users</h4>
          <div className="mx-4">
            <DateRangePicker />
          </div>
        </div>

        <Row className="g-4 gx-md-5 my-4">
          <Col lg="3">
            <Row className="gy-3 gx-3">
              <Col sm="6" lg="12">
                <div className="p-3 shadow-sm rounded-3 d-flex align-items-center gap-3">
                  <div className="bg-secondary-10 w-50px rounded-8 ratio-1x1 d-flex justify-content-center align-items-center">
                    <img src={manageUser} alt="User Icon" width="16px" />
                  </div>
                  <div>
                    <h4>{data?.activeUsers}</h4>
                    <p className="fs-12px m-0">Active users</p>
                  </div>
                </div>
              </Col>
              <Col sm="6" lg="12">
                <div className="p-3 shadow-sm rounded-3 d-flex align-items-center gap-3">
                  <div className="bg-secondary-10 w-50px rounded-8 ratio-1x1 d-flex justify-content-center align-items-center">
                    <img src={moneyIcon} alt="User Icon" width="16px" />
                  </div>
                  <div>
                    <h4>{data?.creditsConsumed}</h4>
                    <p className="fs-12px m-0">Total Credits consumed</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm="6" lg="4" xl="3">
            <div>
              <p>{countryArray.title}</p>
              {countryArray.data.length > 0 && (
                <ol>
                  {countryArray.data.map((item, index) => {
                    return (
                      <li key={index} style={{
                        cursor: "pointer"
                      }}
                        onClick={()=> {
                          const workSheet = XLSX.utils.json_to_sheet(item.data);
                          const workBook = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(workBook, workSheet, item.countryName);
                          XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
                          XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
                          XLSX.writeFile(workBook, `${item.countryName}.xlsx`);
                        }}>
                        <div className="d-flex flex-wrap justify-content-between">
                          <span>{item.countryName}</span>
                          <span>{item.users}</span>
                        </div>
                      </li>
                    );
                  })}
                </ol>
              )}
            </div>
          </Col>
          <Col sm="6" lg="4" xl="3">
            <div>
              <p>{winaryArray.title}</p>
              {winaryArray.data.length > 0 && (
                <ol>
                  {winaryArray.data.map((item, index) => {
                    return (
                      <li key={index} style={{
                        cursor: "pointer"
                      }}
                        onClick={()=> {
                          const workSheet = XLSX.utils.json_to_sheet(item.data);
                          const workBook = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(workBook, workSheet, "Winery Data");
                          XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
                          XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
                          XLSX.writeFile(workBook, `${item.wineryName}.xlsx`);
                        }}
                      >
                        <div className="d-flex flex-wrap justify-content-between">
                          <span>{item.wineryName}</span>
                          <span>{item.visits}</span>
                        </div>
                      </li>
                    );
                  })}
                </ol>
              )}
            </div>
          </Col>
        </Row>

        <form
          onSubmit={handleCreditSubmit((data) => {
            data.credits = parseInt(data.credits);
            addCredits(data);
          })}
          className="d-flex align-items-center gap-4 flex-wrap"
        >
          <h5 className="m-0">Add credit to user</h5>
          <Input
            sx={{ maxWidth: "300px" }}
            label=" "
            id="searchEmail"
            placeholder="Search by email"
            TextInput={CustomTextField}
            control={creditControl}
            name="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={searchImg}
                    alt="Search Img"
                    width={"24px"}
                    height="24px"
                    className="img-fluid obj-fit-cover obj-position-center"
                  />
                </InputAdornment>
              ),
            }}
          />
          <Input
            sx={{ maxWidth: "150px" }}
            label=" "
            name="credits"
            TextInput={CustomTextField}
            control={creditControl}
            id="creditSearch"
            placeholder="nº credits"
            type={"number"}
          />
          <Button type="success" variant="success">
            Add credit to balance
          </Button>
        </form>
      </section>
    </section>
  );
};
