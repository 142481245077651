import React from "react";
import { Product } from "Components/Product";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import jonathansWinery from "assets/images/product/jonathans-winery.png";
import brotherswinery from "assets/images/product/brothers-winery.png";
import davidsWinery from "assets/images/product/david-winery.png";
import roseayWinery from "assets/images/product/roseay-winery.png";

export const Cellar = () => {
  const productArr = [
    {
      title: "Jonathans Winery",
      url: "/",
      img: jonathansWinery,
    },
    {
      title: "David Winery",
      url: "/",
      img: davidsWinery,
    },
    {
      title: "Brothers Winery",
      url: "/",
      img: brotherswinery,
    },
    {
      title: "Roseay Winery",
      url: "/",
      img: roseayWinery,
    },
    {
      title: "Jonathans Winery",
      url: "/",
      img: jonathansWinery,
    },
    {
      title: "David Winery",
      url: "/",
      img: davidsWinery,
    },
    {
      title: "Brothers Winery",
      url: "/",
      img: brotherswinery,
    },
    {
      title: "Roseay Winery",
      url: "/",
      img: roseayWinery,
    },
  ];
  return (
    <>
      <h2 className="mb-3">Welcome Name</h2>
      <h6 className="mb-5">Your NFTs Cellar Collection</h6>
      <Row className="g-3">
        {productArr.map((product, index) => {
          return (
            <Col sm="6" md="4" lg="3" key={index}>
              <Product {...product} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
