import React, { useMemo } from "react";
import { countries } from "Api/country";
import { Link, Navigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "react-bootstrap/Button";
import MenuItem from "@mui/material/MenuItem";
import Input from "utils/Input";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { signUp } from "store/auth/actions";
import winetourism from "../../assets/images/brands/winetourismlogo.svg";
const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
  name: yup.string().required("Name is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  country: yup.string().required("Country is required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  terms: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions"),
});

const ALLOWED_TOKENS = [{
  name: "winetourism",
  logo: winetourism,
}];

export const Register = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, control } = useForm({ resolver });
  const { token } = useParams();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(
      signUp(
        {
          ...data,
          reference: token,
        },
        {
          onSuccess: (data) => {
            console.log(data);
          },
        }
      )
    );
  };
  const ref = useMemo(()=> {
    return ALLOWED_TOKENS.find((item) => item.name === token)
  }, [token])
  console.log(ref, token,!token || ref)
  return !token || ref ? (
    <div>
      <h5 className="mb-5">Create your free account and receive 1 free credit</h5>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "100%" },
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          sx={{ mb: 3 }}
          id="name"
          label="Full Name"
          variant="outlined"
          fullWidth
          name="name"
          control={control}
        />
        <Input
          sx={{ mb: 3 }}
          id="email"
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          control={control}
        />
        <Input
          id="country"
          sx={{ mb: 3 }}
          label="Country"
          variant="outlined"
          name="country"
          fullWidth
          select
          control={control}
        >
          {countries.map((country, index) => {
            return (
              <MenuItem value={country.text} key={index}>
                <div
                  className={`fi fi-${country.id} me-2 rounded-circle border w-20 h-20`}
                ></div>
                {country.text}
              </MenuItem>
            );
          })}
        </Input>
        <Input
          id="password"
          label="Password"
          sx={{ mb: 3 }}
          variant="outlined"
          name="password"
          type="password"
          fullWidth
          control={control}
        />
        <Input
          id="confirm_password"
          label="Confirm Password"
          variant="outlined"
          sx={{ mb: 3 }}
          type="password"
          name="confirm_password"
          fullWidth
          control={control}
        />
        <Controller
          control={control}
          name="terms"
          defaultValue={false}
          render={({ field: { value, ...field }, fieldState: { error } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} {...field} size="small" />}
              label={
                <span className={`${error && "text-danger"} font-normal`}>
                  {error ? (
                    <>
                      You must accept the{" "}
                      <a
                        href="https://secondwinery.com/privacy"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </>
                  ) : (
                    <>
                      I agree to the{" "}
                      <a
                        href="https://secondwinery.com/privacy"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </>
                  )}
                </span>
              }
            />
          )}
        />
        <div className="mb-4 d-flex">
          <Button type="submit" variant="success">
            CREATE MY FREE ACCOUNT
          </Button>
          {ref?.logo && (
            <img
              src={ref?.logo}
              alt="logo"
              className="ms-auto"
              style={{ width: "min(130px, 50%)" }}
            />
          )}
        </div>
        <p className="m-0">
          Already have an account?{" "}
          <Link
            to={"/"}
            variant="link"
            className="text-secondary text-decoration-none p-0 shadow-none"
          >
            Login
          </Link>
        </p>
      </Box>
    </div>
  ) : (
    <Navigate to="/" />
  );
};
