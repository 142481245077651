import useYupValidationResolver from "hooks/useYupValidationResolver";
import React from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "store/auth/actions";
import Input from "utils/Input";
import * as yup from "yup";

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const Reset = () => {
  const resolver = useYupValidationResolver(schema);
  const { verificationId } = useSelector((s) => s.auth);
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm({ resolver });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    data.token = verificationId;
    dispatch(
      resetPassword(data, {
        onSuccess: (res) => {
          toast.success(res.data.message);
          navigate("/");
        },
      })
    );
  };
  return (
    <div>
      <form action="/" onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="password"
          label="Enter New Password"
          sx={{ mb: 3 }}
          variant="outlined"
          name="password"
          type="password"
          fullWidth
          control={control}
        />
        <Input
          id="confirm_password"
          label="Re-enter New Password"
          variant="outlined"
          sx={{ mb: 3 }}
          type="password"
          name="confirm_password"
          fullWidth
          control={control}
        />
        <Button type="submit" variant="success">
          RESET PASSWORD
        </Button>
      </form>
    </div>
  );
};

export default Reset;
