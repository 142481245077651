import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import searchImg from "assets/images/icons/search.svg";
import { WineryForm } from "Components/WineryForm";
import { CustomTextFieldRounded } from "utils/mui";
import { Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWineries, updateWinery } from "store/wineries/actions";
import { useState } from "react";

export const EditWinery = () => {
  const dispatch = useDispatch();
  const [winery, setWinery] = useState(null);
  const { wineries = [], isLoading } = useSelector((state) => state.wineries);
  useEffect(() => {
    if (wineries.length === 0) {
      dispatch(getWineries("all",{
        onSuccess: (response) => {
          if(response.data.data.length === 0) {
            setWinery(null);
          }
        }
      }));
    } else {
      setWinery(w=> w ? wineries.find((inner) => inner.id === w.id) : wineries[0]);
    }
  }, [wineries, dispatch]);
  const onSubmit = (wineryNew, functions) => {
    dispatch(updateWinery({ _id: winery._id, winery: wineryNew },functions));
  };
  return (
    <div className="p-4 rounded-12 bg-white">
      <div className="d-flex flex-wrap align-items-center gap-4 mb-4">
        <h4 className="mb-0">Select winery </h4>
        <Autocomplete
          id="searchWinery"
          options={wineries}
          getOptionLabel={(option) => option.name}
          style={{ width: 250 }}
          loading={isLoading}
          onChange={(event, value) => setWinery(value)}
          value={winery}
          renderInput={(params) => (
            <CustomTextFieldRounded
              {...params}
              id="searchWinery"
              placeholder="Search Winery"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={searchImg}
                      alt="Search Img"
                      width={"24px"}
                      height="24px"
                      className="img-fluid obj-fit-cover obj-position-center"
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <WineryForm status="editing" winery={winery} onSubmit={onSubmit} />
    </div>
  );
};
