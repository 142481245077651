import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ProductHorizontal } from "Components/ProductHorizontal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getHistoricVisits } from "store/wineries/actions";
import Loader from "utils/Loader";

export const HistoricVisit = () => {
  const { user } = useSelector((state) => state.auth);
  const { historicVisits=[], isLoading } = useSelector((state) => state.wineries);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHistoricVisits());
  },[dispatch]);
  return (
    <div style={{
      minHeight: "100vh",
    }}>
      <h4 className="mb-3">Welcome {user?.name},</h4>
      <div className="d-flex gap-3 justify-content-between mb-3">
        <div>
          <h6 className="mb-5 m-0">Explore your historic visits</h6>
        </div>
        <div>
          {/* <DateRangePicker /> */}
        </div>
      </div>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <Row className="g-3">
          {historicVisits.map((product, index) => {
            return (
              <Col sm="6" lg="6" key={index}>
                <ProductHorizontal {...product} />
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};
