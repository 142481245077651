import Accordion from "Components/Accordion";
import React from "react";

const FAQ = () => {
  return (
    <div className="min-vh-100 px-md-5">
      <h3 className="mb-3">Help & Support</h3>
      <div className="d-flex gap-3 justify-content-between mb-3">
        <div>
          <h6 className="mb-4 m-0">Frequently Asked Questions</h6>
        </div>
      </div>
      <div className="conatiner">
        <Accordion question="How to start with your first visit?">
          First time you access to our platform you will receive a free credit,
          please click "CLAIM" and 1 credit will be added for free to your
          account. Select a winery from "Explore" or filter by country or region
          to access. You will see all the information about the winery, click to
          Start and the credit will be deducted from your account. You will see
          two options, Streaming or Download. With streaming you could access
          without needed a powerfull computer, all the graphics computation are
          made in our side. If you want to visualize in VR you need to donwload
          the file and execute in your computer, but need a powerfull graphic
          card and VR glasses.
        </Accordion>
        <Accordion question="How many time I have to visualize a winery?">
          In you select "Streaming" you will have 30 minutes to visit the
          winery. You could stop anytime and re-start again visiting your
          "Historic Visits". Once the time over you will need to pay again with
          your credits to visit. Why? The streaming proccess is very complicated
          and expensive for us, and required a cloud powerfull server proccesing
          all the graphics to allow that any user with a simple PC could
          visualize in real time.
        </Accordion>
        <Accordion question="How to add credits to my account balance?">
          We have 3 packages to add credits to your account, starting with 5
          credits, 10 credits, and the recommended one with 15 credits… the most
          affordable in terms of price per credit. You need the credits to
          access to visualize the wineries. Also you have a simple option to buy
          only 1 credit.
        </Accordion>
        <Accordion question="Differences between Streaming and Download">
          We use the streaming option to give the opportunity to all users to
          access a 3D recreation with amazing details, that need a very
          powerfull computer to visualize, but we did in our own servers, so you
          don´t have to worry about this. In case you have a top graphic card in
          your PC and want to see in VR, you could download the file and execute
          in your PC.
        </Accordion>
        <Accordion question="What I need to visualize in Virtual Reality?">
          VR need a powerfull PC and VR glasses, we recommended Meta Quest with
          link cable/wifi connection to PC or any other glasses compatible with
          Windows Media Reality.
        </Accordion>
        <Accordion question="More questions? Contact us">
          If you have any other questions or some issues, please contact us at
          <a href="mailto:support@secondwinery.com" className="text-secondary">
            support@secondwinery.com
          </a>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
