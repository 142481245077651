import API from "helpers/api";

const signUp = (data) => {
  return API.post("/auth/register", data);
};

const verify = (data) => {
  console.log(data);
  return API.post("/auth/verify", data);
};

const login = (data) => {
  return API.post("/auth/login", data);
};

const getUser = () => {
  return API.get("/auth/get-user");
};

const addVisit = (id) => {
  return API.post("/auth/deduct-credits", { id });
};

const updateUser = ({ id, ...user }) => {
  return API.put(`/auth/update/${id}`, user);
};

const makePayment = (data) => {
  return API.post("/auth/stripe/payment", data);
};

const deductCredits = (data) => {
  return API.post("/auth/deduct-credits", data);
};

const forgotPassword = (data) => {
  return API.post("/auth/forgot-password", data);
};

const verifyToken = (data) => {
  return API.post("/auth/verify-token", data);
}

const resetPassword = (data) => {
  return API.post("/auth/reset-password", data);
}

export {
  signUp,
  verify,
  login,
  getUser,
  addVisit,
  updateUser,
  makePayment,
  deductCredits,
  forgotPassword,
  verifyToken,
  resetPassword,
};
