import axios from "axios";
import { store } from "../store"
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

API.interceptors.request.use(config => {
  const { auth } = store.getState();
  const rawToken = localStorage.getItem('persist:auth');
  const localStorageToken = rawToken ? (JSON.parse(rawToken) || {})?.token : null;
  const token = auth.token || (localStorageToken ? JSON.parse(localStorageToken) : null);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default API;
