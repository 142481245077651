import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FeatureProduct } from "Components/FeatureProduct";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "react-bootstrap/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { countries } from "Api/country";
import searchImg from "assets/images/icons/search.svg";
import selectImg from "assets/images/icons/select-icon.svg";

// import TextField from "@mui/material/TextField";
// import styled from "@emotion/styled";
// const CustomTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "#D9D9D9",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#D9D9D9",
//   },
//   "& .MuiOutlinedInput-root": {
//     marginTop: "0px",
//     paddingTop: "0px",
//     paddingBottom: "0px",
//     height: "50px",
//     borderRadius: "50px",

//     "& fieldset": {
//       borderColor: "#D9D9D9",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#D9D9D9",
//     },
//     "&:hover fieldset": {
//       borderColor: "#D9D9D9",
//     },
//   },
// });
import { CustomTextLabelFieldRounded } from "utils/mui";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWineries } from "store/wineries/actions";
import { useMemo } from "react";
import { Autocomplete } from "@mui/material";
export const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    paginated: { wineries: paginatedWineries, hasMore },
    isLoading,
  } = useSelector((state) => state.wineries);
  const [wineries, setWineries] = useState(paginatedWineries);
  const [search, setSearch] = useState("");
  const [country, setCountry] = useState({});
  const [region, setRegion] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWineries(page));
  }, [page, dispatch]);
  useEffect(() => {
    setWineries(paginatedWineries);
  }, [paginatedWineries]);
  useEffect(() => {
    let filteredWineries;
    if (search) {
      filteredWineries = paginatedWineries.filter((winery) => {
        return winery.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      filteredWineries = paginatedWineries;
    }
    if (country.text) {
      filteredWineries = filteredWineries
        ? filteredWineries.filter((winery) => {
            return winery.country === country.text;
          })
        : paginatedWineries.filter((winery) => {
            return winery.country === country.text;
          });
    } else {
      filteredWineries = filteredWineries
        ? filteredWineries
        : paginatedWineries;
    }
    if (region) {
      filteredWineries = filteredWineries
        ? filteredWineries.filter((winery) => {
            return winery.region === region;
          })
        : paginatedWineries.filter((winery) => {
            return winery.region === region;
          });
    } else {
      filteredWineries = filteredWineries
        ? filteredWineries
        : paginatedWineries;
    }

    setWineries(filteredWineries);
  }, [search, country, region, paginatedWineries]);
  const regionArr = useMemo(() => {
    return [...new Set(wineries.map((winery) => winery.region))];
  }, [wineries]);
  const countriesArr = useMemo(() => {
    return countries.filter((country) => {
      return wineries.some((winery) => winery.country === country.text);
    });
  }, [wineries]);
  return (
    <>
      <h4>
        Welcome <span style={{ color: "#1B0109" }}>{user?.name},</span>
      </h4>

      <section>
        <Row className="g-3 mt-5">
          <Col xs="12">
            <section className="p-4 bg-white rounded-12">
              <h5 className="mb-4">Select a Winery to start your experience</h5>
              <Row className="g-3">
                <Col sm="6" lg="3">
                  <CustomTextLabelFieldRounded
                    fullWidth
                    id="searchWinery"
                    label="Search Winery"
                    placeholder="Search Winery"
                    onChange={(e) => {
                      const { value } = e.target;
                      setSearch(value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={searchImg}
                            alt="Search Img"
                            width={"24px"}
                            height="24px"
                            className="img-fluid obj-fit-cover obj-position-center"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
                <Col sm="6" lg="3">
                  <Autocomplete
                    id="country"
                    options={countriesArr}
                    getOptionLabel={(option) => option.text}
                    renderOption={(props, option) => (
                      <div {...props}>
                        <div
                          className={`fi fi-${option.id} me-2 rounded-circle border w-20 h-20`}
                        ></div>
                        {option.text}
                      </div>
                    )}
                    onChange={(e, value) => {
                      setCountry(value || {});
                    }}
                    renderInput={(params) => (
                      <CustomTextLabelFieldRounded
                        {...params}
                        label="Select Country"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <div
                          className={`fi fi-${country?.id} me-2 rounded-circle border w-20 h-20`}
                        ></div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Col>
                <Col sm="6" lg="3">
                  <Autocomplete
                    id="region"
                    options={regionArr}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {
                      setRegion(value);
                    }}
                    renderInput={(params) => (
                      <CustomTextLabelFieldRounded
                        {...params}
                        label="Select Region"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={selectImg}
                                alt="Search Img"
                                width={"24px"}
                                height="24px"
                                className="img-fluid obj-fit-cover obj-position-center"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </section>
          </Col>
          {wineries.map((featureProduct, index) => {
            return (
              <Col key={index} sm="6" lg="4" xl="3">
                <FeatureProduct {...featureProduct} />
              </Col>
            );
          })}
          {hasMore && (
            <Col xs="12">
              <Button
                variant="dark"
                className="mx-auto w-32 ratio-1x1 d-flex justify-content-center align-items-center rounded-circle text-white cursor-pointer p-0"
                onClick={() => {
                  setPage(page + 1);
                }}
                disabled={isLoading}
              >
                <ExpandMoreIcon />
              </Button>
            </Col>
          )}
        </Row>
      </section>
    </>
  );
};
