import React from "react";
import "./style.scss";
import Container from "react-bootstrap/Container";

export const HeroLayout = (props) => {
  return (
    <section className="heroSectionLayout d-flex align-items-center pb-5">
      <Container>{props.children}</Container>
    </section>
  );
};
