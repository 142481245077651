import API from "helpers/api";
import { useCallback, useState, useEffect } from "react";
import { wrapper } from "../store";

const useAxiosFetch = (
  url,
  { method = "get", body = {}, pause = false, lazy = false, functions }={}
) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const mutate = useCallback(
    (body2) => {
      const fetchData = async () => {
        if (pause) {
          setLoaded(true);
          return;
        }
        setLoading(true);
        setLoaded(false);
        setData({});
        try {
          const func = (data) => API?.[method.toLowerCase()](url, data);
          const result = await wrapper(functions, func, body2 || body);
          setLoading(false);
          setData(result.data);
        } catch (error) {
          console.error(error);
          setLoading(false);
          setError(error);
        }
        setLoaded(true);
      };
      fetchData();
    },
    [url, body, method, pause, functions]
  );
  useEffect(() => {
    if (lazy) setLoaded(false);
    else mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazy]);
  const updateData = useCallback(
    async (func, funcs) => {
      setLoading(true);
      setLoaded(false);
      const res = await wrapper(funcs, func);
      if(res.data) {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
        setLoaded(true);
      } else {
        mutate();
      }
    },
    [mutate]
  );
  return { data, loading, error, loaded, mutate, updateData };
};

export default useAxiosFetch;
