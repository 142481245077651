import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import locationIcon from "assets/images/icons/location.png";
import worldIcon from "assets/images/icons/world.svg";
import { TextIconButton } from "Components/TextIconButton";

export const ProductHorizontal = (product) => {
  return (
    <Row className="g-0 bg-white rounded-12 overflow-hidden h-100">
      <Col md="5">
        <div className="h-100 position-relative">
          <img
            src={product.images[0]}
            alt={product.name}
            loading="lazy"
            style={{ aspectRatio: "16/14" }}
            className="obj-fit-cover img-fluid obj-position-center"
          />
          {product.comingSoon ? (
            <div className="d-inline-block fs-14px bg-white position-absolute top-24 end-0 px-3 py-1 rounded-pill rounded-end-0">
              Coming Soon
            </div>
          ) : product.isFeatured ? (
            <div className="d-inline-block fs-14px bg-white position-absolute top-24 end-0 px-3 py-1 rounded-pill rounded-end-0">
              Featured
            </div>
          ) : null}
        </div>
      </Col>
      <Col md="7">
        <div className="p-3 h-100">
          <section className="d-flex flex-column h-100 justify-content-between">
            <div>
              <p className="fw-semibold mb-2">{product.name}</p>
              {product.region && (
                <p className="fs-12px mb-2">
                  <img
                    src={locationIcon}
                    loading="lazy"
                    alt="Location"
                    width={"16px"}
                    height={"16px"}
                    className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
                  />
                  {product.region}
                </p>
              )}

              <div className="d-flex justify-content-between flex-wrap">
                {product.country && (
                  <p className="fs-12px">
                    <img
                      src={worldIcon}
                      loading="lazy"
                      alt="world"
                      width={"16px"}
                      height={"16px"}
                      className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
                    />
                    {product.country}
                  </p>
                )}
                <div>
                  <img
                    src={product.logo}
                    alt="Brand Img"
                    width={"100%"}
                    style={{
                      aspectRatio: "2",
                    }}
                    className="obj-fit-contain obj-position-center mh-50px"
                  />
                </div>
              </div>
            </div>
            <TextIconButton
              url={`/winery/${product._id}`}
              text="Continue your visit"
            />
          </section>
        </div>
      </Col>
    </Row>
  );
};
