import React from "react";
import "./style.scss";
import { TextIconButton } from "Components/TextIconButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makePayment } from "store/auth/actions";
import { useStripe } from "@stripe/react-stripe-js";
export const CreditPackage = (creditPackage) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const { sessionId } = useSelector((state) => state.auth);
  useEffect(() => {
    if (sessionId && stripe) {
      stripe.redirectToCheckout({ sessionId });
    }
  }, [sessionId, stripe]);
  return (
    <section className={`${creditPackage.color} package`}>
      {creditPackage.recomemded && (
        <div className="text-center fw-bold p-2 rounded-8 lh-lg text-white fs-14px recomendedTag">
          Recommended
        </div>
      )}
      <section className="p-3 ps-0 border bg-white rounded-3">
        <div className="fs-14px px-3 py-1 d-inline-block lh-lg pkgTag mb-3">
          Pack of{" "}
          <span className="fw-bold">{creditPackage.creditNumber} credits</span>
        </div>
        <div className="p-3">
          <h4 className="m-0">
            <span className="h3 pkgname">{creditPackage.price}</span>/credit
          </h4>
          <p className="fs-12px">({creditPackage.tagLine})</p>
          {creditPackage.points.length > 0 && (
            <ul className="list-circle fs-12px my-4">
              {creditPackage.points.map((point, index) => {
                return <li key={index}>{point}</li>;
              })}
            </ul>
          )}

          <TextIconButton
            className={`bg-${creditPackage.color}`}
            url={creditPackage.url}
            text={`save ${creditPackage.save}`}
            onClick={(e) => {
              console.log()
              e.preventDefault();
              dispatch(
                makePayment({
                  name: process.env[creditPackage.name],
                  quantity: creditPackage.creditNumber,
                  success_url: `${window.location.origin}/user`,
                  cancel_url: `${window.location.origin}/user`,
                })
              );
            }}
          />
        </div>
      </section>
    </section>
  );
};
