import API from "helpers/api";

const getWineries = () => {
  return API.get("/wineries");
};

const addWinery = (winery) => {
  return API.post("/wineries/add", winery);
};

const updateWinery = (data) => {
  return API.put(`/wineries/${data._id}`, data.winery);
};

const deleteWinery = (id) => {
  return API.delete(`/wineries/${id}`);
};

const getWinery = (id) => {
  return API.get(`/wineries/${id}`);
};

const getFeaturedWineries = () => {
  return API.get("/wineries/get/featured");
};

const getWineriesByCountry = (country) => {
  return API.get(`/wineries/get/country/${country}`);
};

const removeImage = ({ id, image }) => {
  console.log(id, image);
  return API.delete(`/wineries/remove-image/${id}`, { data: { image } });
}

const getPaginatedWineries = (page) => {
  return API.get(`/wineries/page/${page}`);
};

const getHistoricVisits = () => {
  return API.get("/wineries/get/historic-visits");
}

export {
  getWineries,
  addWinery,
  updateWinery,
  deleteWinery,
  getWinery,
  getFeaturedWineries,
  getWineriesByCountry,
  removeImage,
  getPaginatedWineries,
  getHistoricVisits
};
