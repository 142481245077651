import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NftProduct } from "Components/NftPrduct";
import { useMemo } from "react";
import { Button } from "react-bootstrap";
import { HiArrowRight } from "react-icons/hi";
export const NftProductSection = ({ wines = [], collection_url, ...props }) => {
  const allWines = useMemo(
    () => wines.filter((e) => e.title || e.description || e.image).slice(0, 3),
    [wines]
  );
  return (
    <Row className="g-4">
      {allWines.length > 0 ? (
        <>
          {allWines.map((product, index) => {
            return (
              <Col key={index} {...props}>
                <NftProduct {...product} />
              </Col>
            );
          })}
          <Col xs={12} className="text-center">
            <Button as={"a"} href={collection_url} variant="secondary" className="text-uppercase px-4 py-3 rounded-pill">
              EXPLORE ALL <HiArrowRight className="ms-3" />
            </Button>
          </Col>
        </>
      ) : (
        <div className="text-center h5 fw-bold py-5">No wines available</div>
      )}
    </Row>
  );
};
