import React, { Suspense } from "react";
import { HeroLayout } from "Components/HeroSection/Layout";
import Container from "react-bootstrap/Container";
import { HomeSection } from "Components/HeroSection/HomeSection";
import { NftProductSection } from "Components/NftProductSection";
import { defer, Await, useLoaderData } from "react-router-dom";
import API from "helpers/api";
import { CircularProgress } from "@mui/material";
export const WinderyDetail = () => {
  const loaderData = useLoaderData();
  return (
    <>
      <Suspense fallback={<div className="vh-100 w-100 d-flex justify-content-center align-items-center">
        <CircularProgress />
      </div>}>
        <Await resolve={loaderData?.winery}>
          {(data) => (
            <>
              <HeroLayout>
                <HomeSection winery={data?.data || {}} />
              </HeroLayout>
              <Container className="my-5 pb-5">
                <section className="my-5">
                  <h3>Collectibles NFTs</h3>
                  <p>Once you collect will be stored in your digital cellar</p>
                </section>
                <NftProductSection
                  wines={data?.data?.wines}
                  collection_url={data?.data?.collection_url}
                  md="6"
                  lg="4"
                />
              </Container>
            </>
          )}
        </Await>
      </Suspense>
    </>
  );
};

const getWinery = async (id) => {
  return (await API.get(`/wineries/${id}`)).data;
};

/**
 * @type {import("react-router-dom").LoaderFunction}
 */
export const loader = ({ params }) => {
  return defer({
    winery: getWinery(params.id),
  });
};
