import React, { useContext } from "react";
import "./style.scss";
import { Link, NavLink } from "react-router-dom";
import { SidebarContext } from "Context/SidebarContext";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import secondWineryWhite from "assets/images/logo/secondwinery.png";
import radar from "assets/images/icons/radar.svg";
import radarWhite from "assets/images/icons/radar-white.svg";
import security from "assets/images/icons/security-card.svg";
import securityWhite from "assets/images/icons/security-card-white.svg";
import user from "assets/images/icons/user-square.svg";
import userWhite from "assets/images/icons/user-square-white.svg";
import scanCube from "assets/images/icons/3d-cube-scan.svg";
import scanCubeWhite from "assets/images/icons/3d-cube-scan-white.svg";
import archive from "assets/images/icons/archive-book.svg";
import archiveWhite from "assets/images/icons/archive-book-white.svg";
import editWhite from "assets/images/icons/status-up-white.svg";
import edit from "assets/images/icons/status-up.svg";
import uploadWhite from "assets/images/icons/upload-white.svg";
import upload from "assets/images/icons/upload.svg";
import manageUserWhite from "assets/images/icons/user-white.svg";
import manageUser from "assets/images/icons/user.svg";
import login from "assets/images/icons/login.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/auth/actions";
import HelpIcon from '@mui/icons-material/Help';
const sidebarLinkArr = [
  {
    title: "Explore",
    iconActive: radar,
    icon: radarWhite,
    url: "/user",
  },
  {
    title: "Account",
    iconActive: user,
    icon: userWhite,
    url: "/user/account",
  },
  {
    title: "Your credits",
    iconActive: security,
    icon: securityWhite,
    url: "/user/credits",
  },
  {
    title: "Historic Visits",
    iconActive: archive,
    icon: archiveWhite,
    url: "/user/historicvisit",
  },
  {
    title: "Help & Support",
    svg: <HelpIcon color="inherit" />,
    url: "/user/faq",
  },
  {
    title: "NFT Marketplace",
    iconActive: scanCube,
    icon: scanCubeWhite,
    url: "/user/instructions",
    runCheck: true,
  },
];

const adminSidebarLinkArr = [
  {
    title: "Edit Wineries",
    iconActive: edit,
    icon: editWhite,
    url: "/admin/editwinery",
  },
  {
    title: "Upload Wineries",
    iconActive: upload,
    icon: uploadWhite,
    url: "/admin/uploadwinery",
  },
  {
    title: "Manage Users",
    iconActive: manageUser,
    icon: manageUserWhite,
    url: "/admin/manageusers",
  },
  {
    title: "Help & Support",
    url: "/admin/faq",
    svg: <HelpIcon color="inherit" />,
  },
];
const DashboardSidebar = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="d-flex flex-column" style={{
      minHeight: "100vh",
    }}>
      <div className="h-100">
      <Toolbar sx={{ m: ".5rem .5rem 2rem .5rem" }}>
        <Link to="/">
          <img
            src={secondWineryWhite}
            alt="Logo"
            width={"100%"}
            height="auto"
            className="img-fluid obj-fit-cover obj-position-center"
          />
        </Link>
      </Toolbar>
      <Divider />
      <List className="sidebarNav d-flex flex-column">
        {user.role === "user"
          ? sidebarLinkArr.map((link, index) => {
              const Link = link.url.includes("http") ? "a" : NavLink;
              const props = {
                [Link === "a" ? "href" : "to"]: link.url,
                className:
                  Link === "a"
                    ? "sidebarLink"
                    : ({ isActive }) =>
                        isActive ? "sidebarLink active" : "sidebarLink",
                target: Link === "a" ? "_blank" : null,
              };
              return (
                <ListItem key={index} disablePadding>
                  <Link {...props} end onClick={(e)=> {
                    if(link.runCheck) {
                      if(JSON.parse(localStorage.getItem("showInstructions"))) {
                        window.open("https://nft.secondwinery.com", "_blank")
                      }
                    }
                  }}>
                    {Link === "a" ? (
                      <ListItemButton className="sibarItem">
                        <ListItemIcon>
                          {link.svg || <img src={link.icon} alt={link.title} />}
                        </ListItemIcon>
                        <ListItemText
                          primary={link.title}
                          primaryTypographyProps={{
                            color: "#fff",
                          }}
                        />
                      </ListItemButton>
                    ) : (
                      ({ isActive }) => (
                        <ListItemButton className="sibarItem">
                          <ListItemIcon sx={{color: isActive ? "#DF526C" : "#fff",}}>
                          {link.svg ? link.svg : <img src={isActive ? link.iconActive : link.icon} alt={link.title} />}

                          </ListItemIcon>
                          <ListItemText
                            primary={link.title}
                            primaryTypographyProps={{
                              color: isActive ? "#DF526C" : "#fff",
                            }}
                          />
                        </ListItemButton>
                      )
                    )}
                  </Link>
                </ListItem>
              );
            })
          : user.role === "admin"
          ? adminSidebarLinkArr.map((link, index) => {
              return (
                <ListItem key={index} disablePadding>
                  <NavLink
                    to={link.url}
                    end
                    className={({ isActive }) =>
                      isActive ? "sidebarLink active" : "sidebarLink"
                    }
                  >
                    {({ isActive }) => (
                      <ListItemButton className="sibarItem">
                        <ListItemIcon sx={{color: isActive ? "#DF526C" : "#fff",}}>
                        {link.svg ? link.svg : <img src={isActive ? link.iconActive : link.icon} alt={link.title} />}
                        </ListItemIcon>
                        <ListItemText
                          primary={link.title}
                          primaryTypographyProps={{
                            color: isActive ? "#DF526C" : "#fff",
                          }}
                        />
                      </ListItemButton>
                    )}
                  </NavLink>
                </ListItem>
              );
            })
          : ""}
      </List>
      </div>
      <ListItem sx={{ mt: "auto" }} onClick={handleLogout}>
        <ListItemButton className="sibarItem">
          <ListItemIcon>
            <img src={login} alt={"Login"} />
          </ListItemIcon>
          <ListItemText
            primary={"Logout"}
            primaryTypographyProps={{
              color: "#DF526C",
            }}
          />
        </ListItemButton>
      </ListItem>
    </div>
  );
};
export const Sidebar = (props) => {
  const { window } = props;
  const { mobileSidebarOpen, setMobileSidebarOpen, sidebarwidth } =
    useContext(SidebarContext);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const handleSidebarToggle = () => {
    setMobileSidebarOpen(!mobileSidebarOpen);
  };

  return (
    <Box
      component="nav"
      sx={{
        width: { md: sidebarwidth },
        flexShrink: { md: 0 },
      }}
      aria-label="Sidebar Links"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileSidebarOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: sidebarwidth,
            background: "#212529",
          },
        }}
      >
        <DashboardSidebar />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: sidebarwidth,
            background: "#212529",
          },
        }}
        open
      >
        <DashboardSidebar />
      </Drawer>
    </Box>
  );
};
