import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactInputVerificationCode from "react-input-verification-code";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { logout, verify } from "store/auth/actions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import useAxiosFetch from "hooks/useAxiosFetch";
const Verification = () => {
  const {
    isAuthenticated,
    isVerified,
    user: { _id, email },
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate } = useAxiosFetch("/auth/resend-verification", {
    method: "POST",
    functions: {
      onSuccess: (res) => {
        toast.dismiss()
        toast.success(res.data.message);
      },
    },
    lazy: true,
    body: { _id, email },
  })
  const onCompleted = (token) => {
    dispatch(
      verify(
        { id: _id, token },
        {
          onSuccess: () => {
            navigate("/");
          },
        }
      )
    );
  };
  return (
    <Dialog maxWidth="lg" open={isAuthenticated && !isVerified}>
      <DialogTitle>Enter Your Verification Code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the verification code sent to your email address.
        </DialogContentText>
        <div className="p-5">
          <ReactInputVerificationCode
            autoFocus
            length={6}
            onCompleted={onCompleted}
            placeholder=""
          />
        </div>
        <div className="text-center pb-5">
          <p>
            Didn't receive the code?{" "}
            <a href="#" className="text-primary" onClick={()=> {
              toast.loading("Sending verification code...");
              mutate()
            }}>
              Resend
            </a>
          </p>
          <p>
            Login with a different account?{" "}
            <a href="#" className="text-primary" onClick={()=> {
              dispatch(logout({
                onLogout: () => {
                  navigate("/login");
                  toast('User Logged out');
                }
              }));
            }} >
              Logout
            </a>
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Verification;
