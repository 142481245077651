import * as constants from './constants';
import {constants as userConstants} from '../auth/constants';
const initialState = {
  isLoading: false,
  wineries: [],
  error: null,
  featuredWineries: [],
  wineriesByCountry: [],
  historicVisits: [],
  paginated: {
    hasMore: true,
    wineries: [],
  },
};

export default function wineriesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_WINERIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.GET_WINERIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wineries: action.payload.data,
      };
    case constants.GET_WINERIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.GET_WINERY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.GET_WINERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wineries: state.wineries.map(winery => {
          if (winery.id === action.payload.id) {
            return action.payload;
          }
          return winery;
        }),
      };
    case constants.GET_WINERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.ADD_WINERY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.ADD_WINERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wineries: [...state.wineries, action.payload],
      };
    case constants.ADD_WINERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.UPDATE_WINERY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.UPDATE_WINERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wineries: state.wineries.map(winery => {
          if (winery.id === action.payload.id) {
            return action.payload;
          }
          return winery;
        }),
      };
    case constants.UPDATE_WINERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.DELETE_WINERY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.DELETE_WINERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wineries: state.wineries.filter(winery => winery._id !== action.payload._id),
      };
    case constants.DELETE_WINERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.GET_FEATURED_WINERIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.GET_FEATURED_WINERIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        featuredWineries: action.payload,
      };
    case constants.GET_FEATURED_WINERIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.GET_WINERIES_BY_COUNTRY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.GET_WINERIES_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wineriesByCountry: action.payload,
      };
    case constants.GET_WINERIES_BY_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.REMOVE_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.REMOVE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wineries: state.wineries.map(winery => {
          if (winery.id === action.payload.id) {
            console.log(action.payload);
            return action.payload;
          }
          return winery;
        }),
      };
    case constants.REMOVE_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.GET_PAGINATED_WINERIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.GET_PAGINATED_WINERIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paginated: {
          hasMore: action.payload.data.length === 10,
          wineries: action.payload.page === 1 ? action.payload.data : [...state.paginated.wineries, ...action.payload.data],
        }
      };
    case constants.GET_PAGINATED_WINERIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.GET_HISTORIC_VISITS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.GET_HISTORIC_VISITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        historicVisits: action.payload.data,
      };
    case constants.GET_HISTORIC_VISITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case userConstants.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
