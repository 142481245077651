import { constants } from "./constants";

const initialState = {
  isAuthenticated: false,
  user: {},
  isLoading: false,
  error: {},
  success: {},
  isVerified: false,
  sessionId: "",
  verificationId: "",
  resetEmail: "",
  isNewUser: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.USER_LOGIN_REQUEST:
      return {
        ...initialState,
        ...state,
        isLoading: true,
      };
    case constants.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload.user,
        isVerified: action.payload.user.isVerified,
        token: action.payload.token,
        sessionId: "",
      };
    case constants.USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: {},
        isVerified: false,
        error: action.payload,
      };
    case constants.USER_REGISTER_REQUEST:
      return {
        ...initialState,
        ...state,
        isLoading: true,
      };
    case constants.USER_REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload.data,
        isVerified: false,
        sessionId: "",
      };
    case constants.USER_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: {},
        isVerified: false,
        error: action.payload,
      };
    case constants.USER_LOGOUT:
      return {
        ...initialState,
      };
    case constants.USER_VERIFY_REQUEST:
      return {
        ...initialState,
        ...state,
        isLoading: true,
      };
    case constants.USER_VERIFY_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
        isVerified: action.payload.user.isVerified,
        isLoading: false,
        isAuthenticated: true,
        token: action.payload.token,
        isNewUser: action.payload.isNewUser,
      };
    case constants.USER_VERIFY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
        isVerified: !!action.payload.isVerified,
        sessionId: "",
      };
    case constants.GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: {},
        error: action.payload,
      };
    case constants.ADD_VISITED_WINERY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.ADD_VISITED_WINERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case constants.ADD_VISITED_WINERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: { ...state.user, ...action.payload },
      };
    case constants.UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.MAKE_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionId: action.payload,
      };
    case constants.MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.DEDUCT_CREDITS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.DEDUCT_CREDITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case constants.DEDUCT_CREDITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetEmail: action.payload,
      };
    case constants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.VERIFY_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verificationId: action.payload,
      };
    case constants.VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        resetEmail: ""
      };
    case constants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        verificationId: "",
        resetEmail: "",
        isLoading: false,
      };
    case constants.RESET_PASSWORD_FAILURE:{
      if(action.payload.code === "samePassword") {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
          verificationId: "",
          resetEmail: ""
        };
      }
    }
    case constants.REMOVE_NEW_USER:
      return {
        ...state,
        isNewUser: false,
      };
    default:
      return state;
  }
};

export { userReducer };
