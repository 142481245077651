import React from "react";
import { toast } from "react-hot-toast";
import ReactInputVerificationCode from "react-input-verification-code";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "store/auth/actions";

const Verify = () => {
  const dispatch = useDispatch();
  const { resetEmail } = useSelector(s=> s.auth);
  const onCompleted = (token) => {
    dispatch(
      verifyToken(
        { token, email: resetEmail },
        {
          onSuccess: (res) => {
            toast.success(res.data.message);
          },
        }
      )
    );
  };
  return (
    <div>
      <p className="mb-0">Enter the code below</p>
      <ReactInputVerificationCode
        autoFocus
        length={6}
        onCompleted={onCompleted}
        placeholder=""
        style={{
          maxWidth: "100%",
        }}
      />
    </div>
  );
};

export default Verify;
