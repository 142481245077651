import { wrapper } from "..";
import { constants } from "./constants";
import * as service from "./service";
import toast from "react-hot-toast";
import axios from "axios";
const signUp = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.USER_REGISTER_REQUEST });
  try {
    data.ip = await getIpAddress()
    const response = await wrapper(functions, service.signUp, data);
    dispatch({
      type: constants.USER_REGISTER_SUCCESS,
      payload: {
        ...response.data,
      },
    });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: constants.USER_REGISTER_FAILURE, payload: error });
  }
};

export const getIpAddress = async ()=> (await axios.get("https://jsonip.com/")).data.ip

const verify = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.USER_VERIFY_REQUEST });
  try {
    const response = await wrapper(functions, service.verify, data);
    dispatch({
      type: constants.USER_VERIFY_SUCCESS,
      payload: response.data.data,
    });
    toast.success(response.data.message);
  } catch (error) {
    console.log(error);
    dispatch({ type: constants.USER_VERIFY_FAILURE, payload: error });
  }
};

const login = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.USER_LOGIN_REQUEST });
  try {
    data.ip = await getIpAddress()
    const response = await wrapper(functions, service.login, data);
    console.log(response);
    dispatch({
      type: constants.USER_LOGIN_SUCCESS,
      payload: {
        user: response.data.data.user,
        token: response.data.data.token,
      },
    });
    toast.success(response.data.message);
  } catch (error) {
    console.log(error);
    dispatch({ type: constants.USER_LOGIN_FAILURE, payload: error });
  }
};

const logout = (functions) => async (dispatch) => {
  dispatch({ type: constants.USER_LOGOUT });
  functions?.onLogout?.();
};

const getUser = (functions) => async (dispatch) => {
  dispatch({ type: constants.GET_USER_REQUEST });
  try {
    const data = {};
    data.ip = await getIpAddress();
    const response = await wrapper(functions, service.getUser,data);
    dispatch({
      type: constants.GET_USER_SUCCESS,
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    dispatch({ type: constants.GET_USER_FAILURE, payload: error });
  }
};

const addVisit = (visit, functions) => async (dispatch) => {
  dispatch({ type: constants.ADD_VISITED_WINERY_REQUEST });
  try {
    const response = await wrapper(functions, service.addVisit, visit);
    dispatch({
      type: constants.ADD_VISITED_WINERY_SUCCESS,
      payload: response.data.user,
    });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: constants.ADD_VISITED_WINERY_FAILURE, payload: error });
  }
};

const updateUser = (user, functions) => async (dispatch) => {
  console.log(user)
  dispatch({ type: constants.UPDATE_USER_REQUEST });
  try {
    const response = await wrapper(functions, service.updateUser, user);
    dispatch({
      type: constants.UPDATE_USER_SUCCESS,
      payload: response.data.user,
    });
  } catch (error) {
    dispatch({ type: constants.UPDATE_USER_FAILURE, payload: error });
  }
};

const makePayment = (payment, functions) => async (dispatch) => {
  dispatch({ type: constants.MAKE_PAYMENT_REQUEST });
  try {
    const response = await wrapper(functions, service.makePayment, payment);
    dispatch({
      type: constants.MAKE_PAYMENT_SUCCESS,
      payload: response.data.session,
    });
  } catch (error) {
    dispatch({ type: constants.MAKE_PAYMENT_FAILURE, payload: error });
  }
};

const deductCredits = (credits, functions) => async (dispatch) => {
  dispatch({ type: constants.DEDUCT_CREDITS_REQUEST });
  try {
    const response = await wrapper(functions, service.deductCredits, credits);
    dispatch({
      type: constants.DEDUCT_CREDITS_SUCCESS,
      payload: response.data.user,
    });
  } catch (error) {
    dispatch({ type: constants.DEDUCT_CREDITS_FAILURE, payload: error });
  }
}

const forgotPassword = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.FORGOT_PASSWORD_REQUEST });
  try {
    const response = await wrapper(functions, service.forgotPassword, data);
    dispatch({
      type: constants.FORGOT_PASSWORD_SUCCESS,
      payload: response.data.email,
    });
    console.log(response)
  } catch (error) {
    dispatch({ type: constants.FORGOT_PASSWORD_FAILURE, payload: error });
  }
};

/**
 * @typedef functions
 * @property {functions} onRequest - called when the request is made
 * @property {functions} onSuccess - called when the request is successful
 * @property {functions} onFailure - called when the request fails
 * @param {functions} functions
 */

const verifyToken = (data, functions) => async (dispatch) => {
  dispatch({ type: constants.VERIFY_TOKEN_REQUEST });
  try {
    const response = await wrapper(functions, service.verifyToken, data);
    dispatch({
      type: constants.VERIFY_TOKEN_SUCCESS,
      payload: response.data.token,
    });
  } catch (error) {
    dispatch({ type: constants.VERIFY_TOKEN_FAILURE, payload: error });
  }
}

const resetPassword = (data, functions) => async (dispatch) => {
  try {
    const response = await wrapper(functions, service.resetPassword, data);
    dispatch({
      type: constants.RESET_PASSWORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: constants.RESET_PASSWORD_FAILURE, payload: error });
  }
}

export {
  signUp,
  verify,
  logout,
  login,
  getUser,
  addVisit,
  updateUser,
  makePayment,
  deductCredits,
  forgotPassword,
  verifyToken,
  resetPassword
};
