import useYupValidationResolver from "hooks/useYupValidationResolver";
import React from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPassword } from "store/auth/actions";
import Input from "utils/Input";
import * as yup from "yup";



const schema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
});

const Forgot = () => {
  const resolver = useYupValidationResolver(schema);
  const { handleSubmit, control } = useForm({ resolver });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(forgotPassword(data, {
      onSuccess: (res) => {
        toast.success(res.data.message);
      }
    }));
  };
  return (
    <div>
      <h5 className="mb-3">Forgot Password?</h5>
      <form action="/" onSubmit={handleSubmit(onSubmit)}>
        <Input
          sx={{ mb: 3 }}
          id="email"
          label="Email"
          name="email"
          variant="outlined"
          type="email"
          fullWidth
          control={control}
        />
        <div className="mb-4">
          <Button type="submit" variant="success" className="px-5">
            Send Email
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Forgot;
