import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "Components/Footer";
import { WineryHeader } from "Components/WineryHeader";

export const WineryLayout = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <WineryHeader />
      <Outlet />
      <Footer />
    </>
  );
};
