import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { QontoConnector, QontoStepIcon } from "utils/mui";
import Forgot from "./Forgot";
import Reset from "./Reset";
import Verify from "./Verify";
const steps = [
  {
    name: "Enter your email",
    component: Forgot,
  },
  {
    name: "Verify Code",
    component: Verify,
  },
  {
    name: "Reset Password",
    component: Reset,
  },
];

const ForgotPassword = () => {
  const [step, setStep] = useState(0);
  const { verificationId,resetEmail } = useSelector(s=> s.auth);
  const StepComponent = useMemo(() => steps[step].component, [step]);
  useEffect(()=> {
    if(verificationId) setStep(2);
    else if(resetEmail) setStep(1);
    else setStep(0);
  }, [verificationId,resetEmail]);
  return (
    <div className="w-100">
      <Stepper
        connector={<QontoConnector />}
        alternativeLabel
        activeStep={step}
      >
        {steps.map((label) => {
          return (
            <Step key={label.name}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                {label.name}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className="mt-5">
        <StepComponent setStep={setStep} />
      </div>
    </div>
  );
};

export default ForgotPassword;
