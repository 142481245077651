import { InputAdornment } from "@mui/material";
import React, { useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Input = ({ label: lab, control, labelProps, TextInput, ...p }) => {
  const [type, setType] = useState(p.type);
  const isPassword = useMemo(() => p.type === "password", [p.type]);
  const show = useMemo(() => {
    if (type === "password") return true;
    return false;
  }, [type]);
  const CustomInput = TextInput || TextField;
  return (
    <div>
      {control ? (
        <Controller
          control={control}
          name={p.name}
          defaultValue={p.defaultValue || ""}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              fullWidth
              {...p}
              {...field}
              type={type}
              label={lab}
              error={!!error?.message}
              helperText={error?.message}
              id={lab}
              SelectProps={{
                displayEmpty: true,
              }}
              InputProps={{
                ...p?.InputProps,
                endAdornment: isPassword ? (
                  <InputAdornment
                    position="end"
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    onClick={() => setType(show ? "text" : "password")}
                  >
                    {show ? <Visibility/> : <VisibilityOff />}
                  </InputAdornment>
                ) : (
                  p?.InputProps?.endAdornment
                ),
              }}
            />
          )}
        />
      ) : (
        <CustomInput
          fullWidth
          {...p}
          type={type}
          className={`${p.className} shadow-sm shadow-gray-400 rounded-xl`}
          id={lab}
          label={lab}
          SelectProps={{
            className: "py-1",
            displayEmpty: true,
          }}
          InputProps={{
            ...p?.InputProps,
            endAdornment: isPassword ? (
              <InputAdornment
                position="end"
                className="cursor-pointer select-none"
                onClick={() => setType(show ? "text" : "password")}
              >
                <div className="text-xs">{show ? "Show" : "Hide"}</div>
              </InputAdornment>
            ) : (
              p?.InputProps?.endAdornment
            ),
          }}
        />
      )}
    </div>
  );
};

export default Input;