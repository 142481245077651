import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import useYupValidationResolver from "../../hooks/useYupValidationResolver";
import { useForm } from "react-hook-form";
import Input from "utils/Input";
import { useDispatch } from "react-redux";
import { login } from "store/auth/actions";
const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
})

export const Login = () => {
  const resolver = useYupValidationResolver(schema);
  const { handleSubmit,control } = useForm({resolver});
  const dispatch = useDispatch()
  const onSubmit = (data) => {
    dispatch(login(data));
  }

  return (
    <div>
      <h5 className="mb-5">Please Log In to your account</h5>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "100%" },
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          sx={{ mb: 3 }}
          id="email"
          label="Email"
          name="email"
          variant="outlined"
          type="email"
          fullWidth
          control={control}
        />
        <Input
          id="password"
          label="Password"
          sx={{ mb: 3 }}
          variant="outlined"
          name="password"
          type="password"
          fullWidth
          control={control}
        />
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-4 mb-2">
          <Link to="/reset-password" onClick={(e)=> {
            // e.preventDefault();
            // toast.error("Forgot Password is not implemented yet")
          }} className="text-decoration-none">
            Forgot Password?
          </Link>
        </div>
        <div className="mb-4">
          <Button type="submit" variant="success" className="px-5">
            Login
          </Button>
        </div>
        <p className="m-0">
          Don’t have an account?{" "}
          <Link
            to="/register"
            variant="link"
            className="text-secondary text-decoration-none p-0 shadow-none"
          >
            Register for free
          </Link>
        </p>
      </Box>
    </div>
  );
};
