import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Check } from "@mui/icons-material";

export const CustomTextLabelField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#D9D9D9",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D9D9D9",
  },
  "& .MuiOutlinedInput-root": {
    marginTop: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "50px",
    borderRadius: "50px",

    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9",
    },
  },
});

export const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#D9D9D9",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D9D9D9",
  },
  "& .MuiOutlinedInput-root": {
    marginTop: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "50px",

    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9",
    },
  },
});

export const CustomTextLabelFieldRounded = styled(TextField)({
  "& label.Mui-focused": {
    color: "#D9D9D9",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D9D9D9",
  },
  "& .MuiOutlinedInput-root": {
    marginTop: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "50px",
    borderRadius: "50px",

    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9",
    },
  },
});

export const CustomTextFieldRounded = styled(TextField)({
  "& label.Mui-focused": {
    color: "#D9D9D9",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D9D9D9",
  },
  "& .MuiOutlinedInput-root": {
    marginTop: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "50px",
    borderRadius: "50px",

    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9",
    },
  },
});

export const CustomTextAreaField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#D9D9D9",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D9D9D9",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9",
    },
  },
});

export const CustomDateField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#D9D9D9",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    padding: "0px",
    width: "110px",

    "& fieldset": {
      border: "none",
    },
  },
});

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

export function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}