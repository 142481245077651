import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/system/Box";
import { countries } from "Api/country";
import { UploadSection } from "Components/UploadSection";
import previewImg from "assets/images/icons/preview.png";
import Modal from "react-bootstrap/Modal";
import WarningIcon from "@mui/icons-material/Warning";
import { CustomTextField } from "utils/mui";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "utils/Input";
import { deleteWinery, removeImage } from "store/wineries/actions";
import { useDispatch } from "react-redux";
import { DevTool } from "@hookform/devtools";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "config/firebase";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useCallback } from "react";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import EditorConvertToHTML from "utils/Editor";
const credits = new Array(31).fill(0).map((_, i) => i);
const regExp =
  /((ftp|http|https):\/\/)(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  country: yup.string().required("Country is required"),
  email: yup.string().required("Email is required"),
  region: yup.string().required("Region is required"),
  streaming_link: yup.string().required("Streaming link is required"),
  download_link: yup
    .string()
    .matches(regExp, "Invalid download link")
    .required("Download link is required"),
  website: yup
    .string()
    .matches(regExp, "Invalid website")
    .required("Website is required"),
  isFeatured: yup.boolean().required("Is featured is required"),
  comingSoon: yup.boolean().required("Please select an availability for this winery"),
  wines: yup
    .array()
    .of(
      yup.object({
        link: yup
          .string()
          .matches(regExp, "Invalid link")
          .required("Link is required"),
        image: yup.mixed().required("Image is required"),
      })
    )
    .required("Wines is required"),
  collection_url: yup
    .string()
    .matches(regExp, "Invalid collection url")
    .required("Collection url is required"),
});

const isImageProcessed = (wine) => {
  try {
    return !URL.createObjectURL(wine.image);
  } catch (e) {
    return true;
  }
};

export const WineryForm = (props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const { control, handleSubmit, setValue } = useForm({ resolver });
  const setValues = useCallback(() => {
    setValue("name", props.winery?.name || "");
    setValue("description", props.winery?.description || "");
    setValue("country", props.winery?.country || "");
    setValue("region", props.winery?.region || "");
    setValue("streaming_link", props.winery?.streaming_link || "");
    setValue("website", props.winery?.website || "");
    setValue("email", props.winery?.email || "");
    setValue("address", props.winery?.address || "");
    setValue("download_link", props.winery?.download_link || "");
    setValue("credits", props.winery?.credits || 0);
    setValue("isFeatured", props.winery?.isFeatured);
    setValue("wines", props.winery?.wines || []);
    setValue("collection_url", props.winery?.collection_url || "");
    setValue("comingSoon", props.winery?.comingSoon || false);
  }, [props.winery, setValue]);
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState({
    preview: props.winery?.logo || "",
    file: null,
    isDefault: props.winery?.logo ? false : true,
  });
  const [map, setMap] = useState({
    preview: props.winery?.map || "",
    file: null,
    isDefault: props.winery?.map ? false : true,
  });
  useEffect(() => {
    setValues();
    setImages(
      props.winery?.images?.map((el) => ({
        preview: el,
        file: null,
        isDefault: true,
      })) || []
    );
    setLogo({
      preview: props.winery?.logo || "",
      file: null,
      isDefault: props.winery?.logo ? false : true,
    });
    setMap({
      preview: props.winery?.map || "",
      file: null,
      isDefault: props.winery?.map ? false : true,
    });
  }, [props.winery, setValues]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "wines",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    modal: false,
    msg: "",
  });

  const handleModalOpen = (props, i) => {
    if (props === "nft") {
      setConfirmationModal((modal) => ({
        ...modal,
        msg: "Are you sure you want to delete this nft",
        modal: true,
        index: i,
      }));
    } else if (props === "winery") {
      setConfirmationModal((modal) => ({
        ...modal,
        msg: "Are you sure you want to delete this winery",
        modal: true,
        index: null,
      }));
    }
  };

  const handleModalClose = async () => {
    if (confirmationModal.index || confirmationModal.index === 0) {
      remove(confirmationModal.index);
    } else {
      toast.loading("Deleting winery");
      await Promise.all(
        props.winery.images.map((el) => {
          const storageRef = ref(storage, el);
          return deleteObject(storageRef);
        })
      );
      const logoRef = ref(storage, props.winery?.logo);
      await deleteObject(logoRef);
      dispatch(
        deleteWinery(props.winery._id, {
          onSuccess: () => {
            toast.dismiss();
          },
          onError: () => {
            toast.dismiss();
          },
        })
      );
    }
    setConfirmationModal((modal) => ({
      ...modal,
      msg: "",
      modal: false,
      index: null,
    }));
  };
  const onSubmit = async (data) => {
    if (!images) return toast.error("Please upload atleast one image");
    if (images.filter((el) => !el.isDefault).length !== 0) {
      toast.loading("Uploading Images...");
      const uploadingImages = await Promise.all(
        images
          .filter((el) => !el.isDefault)
          .map(async (el) => {
            const refs = ref(
              storage,
              `wineries/${Math.random().toString().slice(2)}`
            );
            const uploadTask = await uploadBytes(refs, el.file);
            return await getDownloadURL(uploadTask.ref);
          })
      );
      data.images = [
        ...images.filter((el) => el.isDefault).map((el) => el.preview),
        ...uploadingImages,
      ];
      toast.dismiss();
      toast.success("Images Uploaded");
    }
    if (logo.file) {
      toast.loading("Uploading Logo...");
      if (props?.winery?.logo) {
        await deleteObject(ref(storage, props.winery?.logo));
      }
      const refs = ref(storage, `logos/${Math.random().toString().slice(2)}`);
      const uploadTask = await uploadBytes(refs, logo.file);
      data.logo = await getDownloadURL(uploadTask.ref);
      toast.dismiss();
      toast.success("Logo Uploaded");
    } else if (!logo.file && !props.winery?.logo) {
      return toast.error("Please upload a logo");
    }
    if (map.file) {
      toast.loading("Uploading Map...");
      if (props?.winery?.map) {
        await deleteObject(ref(storage, props.winery?.map));
      }
      const refs = ref(storage, `maps/${Math.random().toString().slice(2)}`);
      const uploadTask = await uploadBytes(refs, map.file);
      data.map = await getDownloadURL(uploadTask.ref);
      toast.dismiss();
      toast.success("Map Uploaded");
    } else if (!map.file && !props.winery?.map) {
      return toast.error("Please upload a map");
    }
    if (data.wines.length > 0) {
      const t = toast.loading("Uploading Wines...");
      data.wines = await Promise.all(
        data.wines.map(async (el) => {
          if (el.image) {
            try {
              if (!isImageProcessed(el)) {
                const refs = ref(
                  storage,
                  `wines/${Math.random().toString().slice(2)}`
                );
                const uploadTask = await uploadBytes(refs, el.image);
                return {
                  ...el,
                  image: await getDownloadURL(uploadTask.ref),
                };
              } else {
                return el;
              }
            } catch (e) {
              return el;
            }
          }
        })
      );
      toast.dismiss(t);
      toast.success("Wines Uploaded");
    }
    const up = toast.loading(
      `${props.winery ? "Updating" : "Creating"} Winery...`
    );
    dispatch(
      props.onSubmit(data, {
        onSuccess: () => {
          toast.dismiss(up);
        },
      })
    );
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit, (e) => console.log(e))}
      className="p-4 rounded-12 bg-white"
    >
      <section>
        <Row className="g-4 mb-4">
          <Col md="6">
            <p className="fs-14px mb-2">Winery name</p>
            <Input
              fullWidth
              type={"text"}
              id="wineryName"
              name="name"
              TextInput={CustomTextField}
              control={control}
            />
          </Col>
          <Col md="6">
            <p className="fs-14px mb-2">Country</p>
            <Input
              fullWidth
              id="country"
              select
              name="country"
              TextInput={CustomTextField}
              control={control}
            >
              {countries.map((country, index) => {
                return (
                  <MenuItem value={country.text} key={index}>
                    <div
                      className={`fi fi-${country.id} me-2 rounded-circle border w-20 h-20`}
                    ></div>
                    {country.text}
                  </MenuItem>
                );
              })}
            </Input>
          </Col>
          <Col md="6">
            <p className="fs-14px mb-2">Region</p>
            <Input
              fullWidth
              type={"text"}
              id="region"
              name="region"
              TextInput={CustomTextField}
              control={control}
            />
          </Col>
          <Col md="6">
            <p className="fs-14px mb-2">Streaming Link</p>
            <Input
              fullWidth
              type={"text"}
              id="streamingLink"
              name="streaming_link"
              TextInput={CustomTextField}
              control={control}
            />
          </Col>
          <Col md="6">
            <p className="fs-14px mb-2">Address</p>
            <Input
              fullWidth
              type={"text"}
              id="address"
              name="address"
              TextInput={CustomTextField}
              control={control}
            />
          </Col>
          <Col md="6">
            <p className="fs-14px mb-2">Download Link</p>
            <Input
              fullWidth
              type={"text"}
              id="downloadLink"
              name="download_link"
              TextInput={CustomTextField}
              control={control}
            />
          </Col>
          <Col md="6">
            <Row className="g-4">
              <Col xs="12">
                <Row>
                  <Col xs="6">
                    <p className="fs-14px mb-2">Credits to access visit</p>
                    <Input
                      fullWidth
                      id="credits"
                      select
                      name="credits"
                      TextInput={CustomTextField}
                      control={control}
                    >
                      {credits.map((credit, index) => {
                        return (
                          <MenuItem key={index} value={credit}>
                            {credit}
                          </MenuItem>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col xs="6">
                    <p className="fs-14px mb-2">Contact email</p>
                    <Input
                      fullWidth
                      type={"email"}
                      id="contactEmail"
                      name="email"
                      TextInput={CustomTextField}
                      control={control}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="12">
                <p className="fs-14px mb-2">Winery description</p>
                <Controller
                  name="description"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <EditorConvertToHTML
                        onChange={onChange}
                        defaultValue={props.winery?.description}
                      />
                      {error?.message && (
                        <div className="text-danger">{error?.message}</div>
                      )}
                    </>
                  )}
                  control={control}
                />
              </Col>
              <Col xs="12">
                <p className="fs-14px mb-2">Collection URL</p>
                <Input
                  fullWidth
                  type={"text"}
                  id="collectionUrl"
                  name="collection_url"
                  TextInput={CustomTextField}
                  control={control}
                />
              </Col>
              <Col xs="12">
                <Row>
                  <Col xs="6">
                    <p className="fs-14px mb-2">Winery Status</p>
                    <Input
                      fullWidth
                      id="comingSoon"
                      select
                      name="comingSoon"
                      TextInput={CustomTextField}
                      control={control}
                      placeholder=" "
                    >
                      <MenuItem value={true}>Coming Soon</MenuItem>
                      <MenuItem value={false}>Available</MenuItem>
                    </Input>
                  </Col>
                  <Col xs="6">
                    <p className="fs-14px mb-2">Is it Featured?</p>
                    <Input
                      fullWidth
                      id="isFeatured"
                      select
                      name="isFeatured"
                      TextInput={CustomTextField}
                      control={control}
                      placeholder=" "
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Input>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row className="g-4">
              <Col xs="12">
                <p className="fs-14px mb-2">Upload Logo</p>
                <Box className="border p-3 rounded-1 d-flex flex-column justify-content-between align-items-center">
                  {logo.preview ? (
                    <>
                      <img
                        src={logo.preview}
                        alt="Winery Logo"
                        width={"100%"}
                        style={{ maxWidth: 200 }}
                        className="img-fliud obj-fit-cover obj-position-center"
                      />
                      <Button
                        className="mt-4"
                        onClick={() => {
                          setLogo({ preview: null, file: null });
                        }}
                        variant="danger"
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="mb-2">
                        <img
                          src={previewImg}
                          alt="Winery Logo"
                          className="img-fliud obj-fit-cover obj-position-center mw-100px"
                        />
                      </div>
                      <Box component="label" htmlFor="uploadLogo">
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          id="uploadLogo"
                          onChange={(e) => {
                            setLogo({
                              file: e.target?.files?.[0],
                              preview: URL.createObjectURL(
                                e.target?.files?.[0]
                              ),
                            });
                          }}
                        />
                        <span className="text-success cursor-pointer text-decoration-underline">
                          Upload Logo
                        </span>
                      </Box>
                    </>
                  )}
                </Box>
              </Col>
              <Col xs="12">
                <p className="fs-14px mb-2">Upload Map</p>
                <Box className="border p-3 rounded-1 d-flex flex-column justify-content-between align-items-center">
                  {map.preview ? (
                    <>
                      <img
                        src={map.preview}
                        alt="Winery Logo"
                        width={"100%"}
                        style={{ maxWidth: 200 }}
                        className="img-fliud obj-fit-cover obj-position-center"
                      />
                      <Button
                        className="mt-4"
                        onClick={() => {
                          setMap({ preview: null, file: null });
                        }}
                        variant="danger"
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="mb-2">
                        <img
                          src={previewImg}
                          alt="Winery Logo"
                          className="img-fliud obj-fit-cover obj-position-center mw-100px"
                        />
                      </div>
                      <Box component="label" htmlFor="uploadMap">
                        <input
                          onChange={(e) => {
                            setMap({
                              file: e.target?.files?.[0],
                              preview: URL.createObjectURL(
                                e.target?.files?.[0]
                              ),
                            });
                          }}
                          hidden
                          accept="image/*"
                          type="file"
                          id="uploadMap"
                        />
                        <span className="text-success cursor-pointer text-decoration-underline">
                          Upload map
                        </span>
                      </Box>
                    </>
                  )}
                </Box>
              </Col>
              <Col xs="12">
                <p className="fs-14px mb-2">Website</p>
                <Input
                  fullWidth
                  type={"text"}
                  id="website"
                  name="website"
                  TextInput={CustomTextField}
                  control={control}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <h5 className="mb-3">Upload Images</h5>
            <Row className="g-3">
              {images?.map((img, index) => {
                return (
                  <Col xs="3" key={index}>
                    <div className="position-relative d-flex justify-content-center">
                      <div className="h-100px overflow-hidden rounded-12 d-flex justify-content-center align-items-center">
                        <img
                          src={img ? img.preview : null}
                          alt="Upload Img"
                          width="100%"
                          height={"auto"}
                          className="obj-fit-cover obj-position-center"
                        />
                      </div>
                      <Button
                        variant="danger"
                        onClick={async () => {
                          if (img.isDefault) {
                            const deleteObj = async () => {
                              try {
                                const httpRef = ref(storage, img.preview);
                                await deleteObject(httpRef);
                              } catch (error) {
                                throw error;
                              } finally {
                                dispatch(
                                  removeImage(
                                    {
                                      id: props.winery._id,
                                      image: img.preview,
                                    },
                                    {
                                      onSuccess: (d) => {
                                        console.log("success", d);
                                      },
                                    }
                                  )
                                );
                              }
                            };
                            toast.promise(deleteObj(), {
                              loading: "Deleting Image...",
                              success: "Image Deleted",
                              error: "Error Deleting Image",
                            });
                          } else {
                            setImages(
                              images.filter((image, ind) => ind !== index)
                            );
                            toast.success("Image Removed");
                          }
                        }}
                        className="px-3 rounded-1 position-absolute bottom-16px"
                      >
                        Delete Image
                      </Button>
                    </div>
                  </Col>
                );
              })}
              <Col xs="1">
                <UploadSection
                  multiple={true}
                  variant="success"
                  icon="ovalPlus"
                  onChange={(e) => {
                    setImages((i) => [
                      ...i,
                      ...[...e.target.files].map((file) => ({
                        preview: URL.createObjectURL(file),
                        file,
                      })),
                    ]);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      <hr />
      <section>
        <h4 className="mb-4">Add NFTs</h4>
        {fields.map((field, i) => (
          <>
            <Row className="g-4" key={field.id}>
              <Controller
                control={control}
                name={`wines.${i}.image`}
                render={({ field: { onChange, value } }) => (
                  <Col lg="6" xs="12">
                    <p className="fs-14px mb-2">Upload Wine</p>
                    <Box className="border p-3 rounded-1 d-flex flex-column justify-content-between align-items-center">
                      {value ? (
                        <>
                          <img
                            src={(() => {
                              try {
                                return URL.createObjectURL(value);
                              } catch (e) {
                                return value;
                              }
                            })()}
                            alt="Winery Logo"
                            width={"100%"}
                            style={{ maxWidth: 200 }}
                            className="img-fliud obj-fit-cover obj-position-center"
                          />
                          <Button
                            className="mt-4"
                            onClick={() => {
                              onChange(null);
                            }}
                            variant="danger"
                          >
                            Remove
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="mb-2">
                            <img
                              src={previewImg}
                              alt="Winery Logo"
                              className="img-fliud obj-fit-cover obj-position-center mw-100px"
                            />
                          </div>
                          <Box component="label" htmlFor={`wines.${i}.image`}>
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              id={`wines.${i}.image`}
                              onChange={(e) => {
                                onChange(e.target.files[0]);
                              }}
                            />
                            <span className="text-success cursor-pointer text-decoration-underline">
                              Upload Logo
                            </span>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Col>
                )}
              />

              <Col lg="6" xs="12">
                <div className="mb-3">
                  <p className="fs-14px mb-2">NFT Title</p>
                  <Input
                    fullWidth
                    type={"text"}
                    id={field.id}
                    name={`wines.${i}.title`}
                    TextInput={CustomTextField}
                    control={control}
                  />
                </div>
                <Row className="g-4 align-items-end">
                  <Col md="12" xl="8">
                    <p className="fs-14px mb-2">NFT Link</p>
                    <Input
                      fullWidth
                      type={"text"}
                      id={field.id}
                      name={`wines.${i}.link`}
                      TextInput={CustomTextField}
                      control={control}
                    />
                  </Col>
                  <Col md="6" xl="4">
                    <div className="d-flex align-items-center gap-3">
                      <Button
                        variant="danger"
                        className="px-3 rounded-1"
                        onClick={() => {
                          handleModalOpen("nft", i);
                        }}
                      >
                        Delete NFT
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12">
                <p className="fs-14px mb-2">Wine description</p>
                <Controller
                  name={`wines.${i}.description`}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <EditorConvertToHTML
                        onChange={onChange}
                        defaultValue={props.winery?.wines[i]?.description}
                      />
                      {error?.message && (
                        <div className="text-danger">{error?.message}</div>
                      )}
                    </>
                  )}
                  control={control}
                />
              </Col>
            </Row>
            <hr />
          </>
        ))}
        <Button
          variant="info"
          onClick={() => {
            append({ link: "" });
          }}
          className="px-4 mt-4 rounded-1"
        >
          Add NFT
        </Button>
      </section>
      <div className="d-flex align-item-center mt-5 gap-3">
        <Button
          disabled={props.status === "editing" && !props.winery}
          variant="success"
          type="submit"
          className="px-4 rounded-1"
        >
          {props.winery ? "Update Winery" : "Add Winery"}
        </Button>
        {props.status === "editing" && props.winery && (
          <Button
            variant="danger"
            className="px-3 rounded-1"
            onClick={() => {
              handleModalOpen("winery");
            }}
          >
            Delete Winery
          </Button>
        )}
        <Button variant="outline-secondary" className="px-3 rounded-1">
          Cancel
        </Button>
      </div>
      <DevTool control={control} />
      <Modal
        show={confirmationModal.modal}
        onHide={() => {
          setConfirmationModal({
            modal: false,
            msg: "",
          });
        }}
        backdrop="static"
        keyboard={false}
        centered
        className="hello"
      >
        <Modal.Header>
          <Modal.Title className="d-flex align-items-center gap-2">
            <WarningIcon className="text-danger" /> Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmationModal.msg ? confirmationModal.msg : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setConfirmationModal({
                modal: false,
                msg: "",
              });
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleModalClose}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};
