import React, { Component } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

class ImageLightBox extends Component {
  state = {
    lightboxIsOpen: true,
    currentImage: this.props.pos,
    images: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.images) {
      const images = [];
      props.images.forEach((element) => {
        images.push({ source: `${element}` });
      });
      state = {
        images,
      };
      return state;
    }
    return false;
  }
  render() {
    return (
      <ModalGateway>
        {this.props.open ? (
          <Modal onClose={this.props.onClose}>
            <Carousel
              currentIndex={this.props.position}
              views={this.state.images}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }
}

export default ImageLightBox;
