const GET_WINERIES_REQUEST = "GET_WINERIES_REQUEST";
const GET_WINERIES_SUCCESS = "GET_WINERIES_SUCCESS";
const GET_WINERIES_FAILURE = "GET_WINERIES_FAILURE";

const GET_PAGINATED_WINERIES_REQUEST = "GET_PAGINATED_WINERIES_REQUEST";
const GET_PAGINATED_WINERIES_SUCCESS = "GET_PAGINATED_WINERIES_SUCCESS";
const GET_PAGINATED_WINERIES_FAILURE = "GET_PAGINATED_WINERIES_FAILURE";

const GET_WINERY_REQUEST = "GET_WINERY_REQUEST";
const GET_WINERY_SUCCESS = "GET_WINERY_SUCCESS";
const GET_WINERY_FAILURE = "GET_WINERY_FAILURE";

const ADD_WINERY_REQUEST = "ADD_WINERY_REQUEST";
const ADD_WINERY_SUCCESS = "ADD_WINERY_SUCCESS";
const ADD_WINERY_FAILURE = "ADD_WINERY_FAILURE";

const UPDATE_WINERY_REQUEST = "UPDATE_WINERY_REQUEST";
const UPDATE_WINERY_SUCCESS = "UPDATE_WINERY_SUCCESS";
const UPDATE_WINERY_FAILURE = "UPDATE_WINERY_FAILURE";

const DELETE_WINERY_REQUEST = "DELETE_WINERY_REQUEST";
const DELETE_WINERY_SUCCESS = "DELETE_WINERY_SUCCESS";
const DELETE_WINERY_FAILURE = "DELETE_WINERY_FAILURE";

const GET_FEATURED_WINERIES_REQUEST = "GET_FEATURED_WINERIES_REQUEST";
const GET_FEATURED_WINERIES_SUCCESS = "GET_FEATURED_WINERIES_SUCCESS";
const GET_FEATURED_WINERIES_FAILURE = "GET_FEATURED_WINERIES_FAILURE";

const GET_WINERIES_BY_COUNTRY_REQUEST = "GET_WINERIES_BY_COUNTRY_REQUEST";
const GET_WINERIES_BY_COUNTRY_SUCCESS = "GET_WINERIES_BY_COUNTRY_SUCCESS";
const GET_WINERIES_BY_COUNTRY_FAILURE = "GET_WINERIES_BY_COUNTRY_FAILURE";

const REMOVE_IMAGE_REQUEST = "REMOVE_IMAGE_REQUEST";
const REMOVE_IMAGE_SUCCESS = "REMOVE_IMAGE_SUCCESS";
const REMOVE_IMAGE_FAILURE = "REMOVE_IMAGE_FAILURE";

const GET_HISTORIC_VISITS_REQUEST = "GET_HISTORIC_VISITS_REQUEST";
const GET_HISTORIC_VISITS_SUCCESS = "GET_HISTORIC_VISITS_SUCCESS";
const GET_HISTORIC_VISITS_FAILURE = "GET_HISTORIC_VISITS_FAILURE";

export {
  GET_WINERIES_REQUEST,
  GET_WINERIES_SUCCESS,
  GET_WINERIES_FAILURE,

  GET_WINERY_REQUEST,
  GET_WINERY_SUCCESS,
  GET_WINERY_FAILURE,
  
  ADD_WINERY_REQUEST,
  ADD_WINERY_SUCCESS,
  ADD_WINERY_FAILURE,
  
  UPDATE_WINERY_REQUEST,
  UPDATE_WINERY_SUCCESS,
  UPDATE_WINERY_FAILURE,
  
  DELETE_WINERY_REQUEST,
  DELETE_WINERY_SUCCESS,
  DELETE_WINERY_FAILURE,

  GET_FEATURED_WINERIES_REQUEST,
  GET_FEATURED_WINERIES_SUCCESS,
  GET_FEATURED_WINERIES_FAILURE,

  GET_WINERIES_BY_COUNTRY_REQUEST,
  GET_WINERIES_BY_COUNTRY_SUCCESS,
  GET_WINERIES_BY_COUNTRY_FAILURE,

  REMOVE_IMAGE_REQUEST,
  REMOVE_IMAGE_SUCCESS,
  REMOVE_IMAGE_FAILURE,

  GET_PAGINATED_WINERIES_REQUEST,
  GET_PAGINATED_WINERIES_SUCCESS,
  GET_PAGINATED_WINERIES_FAILURE,

  GET_HISTORIC_VISITS_REQUEST,
  GET_HISTORIC_VISITS_SUCCESS,
  GET_HISTORIC_VISITS_FAILURE
};
