import { Button, Dialog, DialogContent } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import img from "assets/images/logo/sw-logo.png";
import useAxiosFetch from "hooks/useAxiosFetch";
import { constants } from "store/auth/constants";
import { toast } from "react-hot-toast";
const ClaimPopup = () => {
  const { isAuthenticated, user, isNewUser, isVerified } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const { loading, mutate } = useAxiosFetch("/auth/claim", {
    method: "POST",
    lazy: true,
    functions: {
      onRequest: ()=> {
        toast.loading("Claiming...");
      },
      onSuccess: (response) => {
        toast.dismiss();
        toast.success(response.data.message);
        dispatch({
          type: constants.GET_USER_SUCCESS,
          payload: response.data.user,
        });
        dispatch({ type: constants.REMOVE_NEW_USER });
      },
      onFailure: (error) => {
        toast.dismiss();
        console.log(error);
        if(error.response.data.refresh) {
          console.log("refresh");
          window.location.reload();
        }
      }
    },
  });
  return (
    <Dialog
      PaperProps={{
        className: "bg-dark rounded-5 p-0",
      }}
      maxWidth="lg"
      open={
        isAuthenticated && isVerified && (!user.creditsRecived || isNewUser)
      }
    >
      <DialogContent className="p-0">
        <Row className="mx-0">
          <Col className="p-5" lg={7}>
            <h4 className="text-center text-white">Thanks for signing up!</h4>
            <ul
              style={{
                listStyle: '"✓   "',
                paddingLeft: "1rem",
              }}
              className="text-white pb-2"
            >
              <li className="pt-4">
                <span>
                  Start explore our wineries, filter by country or region.
                </span>
              </li>
              <li className="pt-4">
                <span>
                  Add credits to your account at "Your credits" to visit the
                  wineries.
                </span>
              </li>
              <li className="pt-4">
                <span>
                  At "Historic Visits" you could see all the wineries visited.
                </span>
              </li>
              <li className="pt-4">
                <span>
                  "NFT Marketplace" allow you to buy digital assets of our
                  wineries.
                </span>
              </li>
            </ul>
            <h6 className="text-center text-white">
              Claim for free your credit to start the first visit
            </h6>
            <div className="text-center d-flex justify-content-center gap-5">
              <Button
                className="mt-4 w-100 rounded-4"
                sx={{ maxWidth: 200, height: 50 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (user.creditsRecived)
                    return dispatch({ type: constants.REMOVE_NEW_USER });
                  mutate();
                }}
              >
                Close
              </Button>
              <Button
                disabled={user.creditsRecived || loading}
                className="mt-4 w-100 rounded-4"
                sx={{ maxWidth: 200, height: 50 }}
                variant="contained"
                color="primary"
                onClick={() => mutate()}
              >
                {user.creditsRecived ? "Already Claimed" : "Claim"}
              </Button>
            </div>
          </Col>
          <Col
            className="p-4 bg-light d-flex justify-content-center align-items-center flex-column"
            lg={5}
          >
            <img
              src={img}
              alt="claim"
              width={"100%"}
              style={{ maxWidth: "300px" }}
            />
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default ClaimPopup;
