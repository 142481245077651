import React from "react";
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AccountSetting } from "Pages/AccountSetting";
import { Credits } from "Pages/Credits";
import { Dashboard } from "Pages/Dashboard";
import { HistoricVisit } from "Pages/HistoricVisit";
import { Layout } from "Pages/Layout";
import { Cellar } from "Pages/Cellar";
import { UploadWinery } from "Pages/UploadWinery";
import { EditWinery } from "Pages/EditWinery";
import { ManageUsers } from "Pages/ManageUsers";
import { WinderyDetail, loader as wineryDataLoader } from "Pages/WinaryDetail";
import { AuthLayout } from "Authentication/AuthLayout";
import { Login } from "Authentication/Login";
import { Register } from "Authentication/Register";
import { WineryLayout } from "Pages/WineryLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ForgotPassword from "Authentication/Forgot";
import FAQ from "Pages/FAQ";
import Instructions from "Pages/Instructions";
import Verification from "utils/verification";
import ClaimPopup from "utils/ClaimPopup";
import { getUser } from "store/auth/actions";

const MyOutlet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (
      user.role &&
      !location.pathname.includes(`/${user.role}`) &&
      !location.pathname.includes("/winery")
    ) {
      navigate(`/${user.role}`);
    }
  }, [user, location, navigate]);
  const dispatch = useDispatch();
  const { isAuthenticated, isVerified } = useSelector((state) => state.auth);
  useEffect(() => {
    if (isAuthenticated && isVerified) {
      dispatch(getUser());
    }
  }, [isAuthenticated, dispatch, isVerified]);
  return (
    <>
      <Outlet />
      <Verification />
      <ClaimPopup />
    </>
  );
};

const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MyOutlet />}>
      <Route path="admin" element={<Layout />}>
        <Route index element={<UploadWinery />} />
        <Route path="uploadwinery" element={<UploadWinery />} />
        <Route path="editwinery" element={<EditWinery />} />
        <Route path="manageusers" element={<ManageUsers />} />
        <Route path="account" element={<AccountSetting />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="instructions" element={<Instructions />} />
      </Route>
      <Route path="/winery" element={<WineryLayout />}>
        <Route path=":id" loader={wineryDataLoader} element={<WinderyDetail />} />
      </Route>
      <Route path="*" element={<div></div>} />
    </Route>
  )
);

const userRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MyOutlet />}>
      <Route path="user" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="credits" element={<Credits />} />
        <Route path="account" element={<AccountSetting />} />
        <Route path="cellar" element={<Cellar />} />
        <Route path="historicvisit" element={<HistoricVisit />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="instructions" element={<Instructions />} />
      </Route>
      <Route path="/winery" element={<WineryLayout />}>
        <Route path=":id" loader={wineryDataLoader} element={<WinderyDetail />} />
      </Route>
      <Route path="*" element={<div></div>} />
    </Route>
  )
);

export const WebRoutes = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  return isAuthenticated ? (
    <RouterProvider router={user.role === "admin" ? adminRouter : userRouter} />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route index element={<Login />} />
          <Route path="register">
            <Route index element={<Register />} />
            <Route path=":token" element={<Register />} />
          </Route>
          <Route path="reset-password" element={<ForgotPassword />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
