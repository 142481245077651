import React from "react";
import "./App.scss";
import { WebRoutes } from "Routes";
import { SidebarContextProvider } from "Context/SidebarContext";
import { toast, Toaster } from "react-hot-toast";
import Verification from "utils/verification";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUser } from "store/auth/actions";
import "config/firebase";
import { ThemeProvider, createTheme } from "@mui/material";
import ClaimPopup from "utils/ClaimPopup";

const theme = createTheme({
  palette: {
    primary: {
      main: "#d74761",
      dark: "#210207",
      light: "#f8f8f8",
      contrastText: "#fff",
    },
  },
});

function App() {
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (user?.notifications?.length > 0) {
      const prevCounts = JSON.parse(
        localStorage.getItem("notificationsCounts")
      );
      if(prevCounts) {
        if (prevCounts?.[user?.id] < user?.notifications?.length) {
          toast.success("You have new notifications");
          localStorage.setItem(
            "notificationsCounts",
            JSON.stringify({
              ...prevCounts,
              [user?.id]: user?.notifications?.length,
            })
          );
        } else {
          localStorage.setItem(
            "notificationsCounts",
            JSON.stringify({
              ...prevCounts,
              [user?.id]: user?.notifications?.length,
            })
          );
        }
      } else {
        localStorage.setItem(
          "notificationsCounts",
          JSON.stringify({
            [user?.id]: user?.notifications?.filter(e=> !e.isRead)?.length,
          })
        );
      }
    }
  }, [user?.notifications?.length]);
  return (
    <ThemeProvider theme={theme}>
      <SidebarContextProvider>
        <WebRoutes />
        <Toaster />
      </SidebarContextProvider>
    </ThemeProvider>
  );
}

export default App;
