const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

const USER_LOGOUT = "USER_LOGOUT";

const USER_VERIFY_REQUEST = "USER_VERIFY_REQUEST";
const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
const USER_VERIFY_FAILURE = "USER_VERIFY_FAILURE";

const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

const GET_USER_REQUEST = "GET_USER_REQUEST";
const GET_USER_SUCCESS = "GET_USER_SUCCESS";
const GET_USER_FAILURE = "GET_USER_FAILURE";

const GET_VISITED_WINERIES_REQUEST = "GET_VISITED_WINERIES_REQUEST";
const GET_VISITED_WINERIES_SUCCESS = "GET_VISITED_WINERIES_SUCCESS";
const GET_VISITED_WINERIES_FAILURE = "GET_VISITED_WINERIES_FAILURE";

const ADD_VISITED_WINERY_REQUEST = "ADD_VISITED_WINERY_REQUEST";
const ADD_VISITED_WINERY_SUCCESS = "ADD_VISITED_WINERY_SUCCESS";
const ADD_VISITED_WINERY_FAILURE = "ADD_VISITED_WINERY_FAILURE";

const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

const MAKE_PAYMENT_REQUEST = "MAKE_PAYMENT_REQUEST";
const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS";
const MAKE_PAYMENT_FAILURE = "MAKE_PAYMENT_FAILURE";

const DEDUCT_CREDITS_REQUEST = "DEDUCT_CREDITS_REQUEST";
const DEDUCT_CREDITS_SUCCESS = "DEDUCT_CREDITS_SUCCESS";
const DEDUCT_CREDITS_FAILURE = "DEDUCT_CREDITS_FAILURE";

const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
const VERIFY_TOKEN_FAILURE = "VERIFY_TOKEN_FAILURE";

const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

const REMOVE_NEW_USER = "REMOVE_NEW_USER";

export const constants = {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,

  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,

  USER_LOGOUT,

  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAILURE,

  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,

  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,

  GET_VISITED_WINERIES_REQUEST,
  GET_VISITED_WINERIES_SUCCESS,
  GET_VISITED_WINERIES_FAILURE,

  ADD_VISITED_WINERY_REQUEST,
  ADD_VISITED_WINERY_SUCCESS,
  ADD_VISITED_WINERY_FAILURE,

  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,

  MAKE_PAYMENT_REQUEST,
  MAKE_PAYMENT_SUCCESS,
  MAKE_PAYMENT_FAILURE,

  DEDUCT_CREDITS_REQUEST,
  DEDUCT_CREDITS_SUCCESS,
  DEDUCT_CREDITS_FAILURE,

  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,

  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
  
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,

  REMOVE_NEW_USER
};
