import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SecondWinarySymbol from "assets/images/logo/secondwinery-symbol.svg";
import SecondWinaryDark from "assets/images/logo/secondwinery-dark.png";
import { Outlet } from "react-router-dom";

export const AuthLayout = () => {
  return (
    <section className="p-2 py-5 p-sm-4 bg-light min-h-100 d-flex justify-content-center align-items-center">
      <Container>
        <Row className="align-items-center g-4">
          <Col lg="6" className="text-center d-none d-lg-block">
            <div>
              <img
                src={SecondWinarySymbol}
                loading="lazy"
                alt="Logo"
                className="img-fluid obj-fit-cover obj-position-center mb-4"
              />
            </div>
            <h3 className="text-center">
              Welcome <br /> to Second Winery
            </h3>
          </Col>
          <Col lg="6">
            <div className="bg-white p-4 rounded-4 py-5 pe-md-5 rounded-end-16 position-relative">
              <>
                <img
                  src={SecondWinaryDark}
                  loading="lazy"
                  alt="Logo"
                  className="img-fluid obj-fit-cover obj-position-center mb-4 mw-250px"
                />
              </>
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
