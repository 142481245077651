import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/system/Box";
import { BsPlus } from "react-icons/bs";
export const UploadSection = (props) => {
  return (
    <Box
      className={`uploadSection w-100 h-100 d-flex justify-content-center align-items-center rounded-3 cursor-pointer ${props.variant}`}
      component="label"
      htmkFor={props.id}
    >
      <input
        hidden
        multiple={props.multiple ? props.multiple : false}
        accept="image/*"
        type="file"
        id={props.id}
        {...props}
      />
      {props.icon === "ovalPlus" ? (
        <div
          className={`px-1 d-flex justify-content-center align-items-center text-${props.variant} border rounded-circle border-2 border-${props.variant}`}
        >
          <BsPlus />
        </div>
      ) : (
        <div className="ratio-1x1 w-32 rounded-pill bg-secondary text-white d-flex justify-content-center align-items-center">
          <AddIcon />
        </div>
      )}
    </Box>
  );
};
