import React from "react";
import { WineryForm } from "Components/WineryForm";
import { useDispatch } from "react-redux";
import { addWinery } from "store/wineries/actions";

export const UploadWinery = () => {
  const dispatch = useDispatch();
  const onSubmit = (data, functions) => {
    dispatch(addWinery(data, functions));
  };

  return (
    <div className="p-4 rounded-12 bg-white">
      <h4 className="mb-4">Upload New Winery</h4>
      <WineryForm status="uploading" onSubmit={onSubmit} />
    </div>
  );
};
