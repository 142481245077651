import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import calenderIcon from "assets/images/icons/calendar.svg";
import CachedIcon from "@mui/icons-material/Cached";
import Modal from "react-bootstrap/Modal";
import { DateTime } from "luxon"
const DateWriter = (props) => {
  return props.date ? (
    DateTime.fromISO(new Date(props.date).toISOString()).toLocaleString(DateTime.DATE_SHORT)
  ) : (
    "--/--/----"
  );
};
export const DateRangePicker = () => {
  const [dateModal, setDateModal] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleDateModal = () => setDateModal(!dateModal);
  return (
    <>
      <div>
        <p className="d-flex gap-2 m-0">
          <span onClick={handleDateModal} className="cursor-pointer">
            <img
              src={calenderIcon}
              alt="Calender Icon"
              width={"20px"}
              height={"auto"}
              className="img-fluid obj-fit-cover obj-position-center"
            />
          </span>
          <span onClick={handleDateModal} className="cursor-pointer">
            <DateWriter date={dateRange[0].startDate || null} />
          </span>
          <span className="text-uppercase fw-semibold">TO</span>{" "}
          <span onClick={handleDateModal} className="cursor-pointer">
            <DateWriter date={dateRange[0].endDate || null} />
          </span>
          <span onClick={handleDateModal} className="cursor-pointer">
            <CachedIcon />
          </span>
        </p>
      </div>
      <Modal
        show={dateModal}
        onHide={handleDateModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Date</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setDateRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
