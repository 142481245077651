import React, { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { countries } from "Api/country";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/system";
import { CustomTextField } from "utils/mui";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useDispatch, useSelector } from "react-redux";
import Input from "utils/Input";
import { DateTime } from "luxon";
import { Avatar, InputAdornment } from "@mui/material";
import TextMaskCustom from "utils/MaskInput";
import { updateUser } from "store/auth/actions";
import toast from "react-hot-toast";
import { ref,uploadBytes,getDownloadURL,deleteObject } from "firebase/storage";
import { storage } from "config/firebase";
const days = new Array(31).fill(0).map((_, i) => i + 1);
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// 50 years ago
const years = new Array(50)
  .fill(0)
  .map((_, i) => new Date().getFullYear() - i - 18);

const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
  name: yup.string().required("Name is required"),
});


export const AccountSetting = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const { user, isLoading } = useSelector((state) => state.auth);
  const [file, setFile] = useState({
    url: null,
    file: null,
  });
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, register } = useForm({
    resolver,
    defaultValues: {
      name: user.name,
      email: user.email,
      country: user.country,
      day: DateTime.fromISO(user.dob).day,
      month: DateTime.fromISO(user.dob).month,
      year: DateTime.fromISO(user.dob).year,
      phone: user.phone,
    },
  });
  const country = watch("country");
  const callingCode = useMemo(() => {
    console.log(country);
    if (country) {
      return countries.find((c) => c.text === country)?.callingid;
    }
  }, [country]);
  const fileRef = useMemo(()=> ref(storage,`users/avatars/${user.id}`),[user.id]);
  const onSubmit = async (data) => {
    if (data.day && data.month && data.year) {
      data.dob = DateTime.fromObject({
        day: data.day,
        month: data.month,
        year: data.year,
      }).toISODate();
    }
    data.id = user._id;
    if(file.file) {
      toast.promise((async ()=> {
        await uploadBytes(fileRef, file.file);
        data.avatar = await getDownloadURL(fileRef);
        dispatch(
          updateUser(data, {
            onSuccess: () => {
              toast.success("Profile updated successfully");
            },
          })
        );
        setFile({
          url: null,
          file: null,
        });
      })(), {
        loading: "Uploading avatar...",
        success: "Avatar uploaded",
        error: "Error uploading avatar",
      })
    } else {
      dispatch(
        updateUser(data, {
          onSuccess: () => {
            toast.success("Profile updated successfully");
          },
        })
      );
    }
  };
  return (
    <div className="p-4 rounded-12 bg-white">
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <h4 className="mb-4">Your user account</h4>
        <section className="border rounded-12 p-3">
          <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-center justify-content-sm-start  align-items-center gap-4 ">
            <div>
              <Avatar
                src={file.url || user.avatar}
                alt="Profile Img"
                width={"100%"}
                style={{ minWidth: 100, minHeight: 100 }}
                className="obj-position-center obj-fit-cover rounded-pill ratio-1x1 mw-150px"
              />
            </div>
            <div>
              <div className="d-flex gap-3 flex-wrap mb-2">
                <Box
                  component="label"
                  className="btn btn-primary"
                  htmlFor="uploadProfile"
                >
                  Upload Now
                  <input
                    onChange={(e) => {
                      setFile({
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                      });
                    }}
                    hidden
                    accept="image/*"
                    type="file"
                    id="uploadProfile"
                  />
                </Box>
                <Button
                  variant="secondary"
                  className="text-capitalize tranparent-10 px-4"
                  onClick={() => {
                    toast.promise(deleteObject(fileRef), {
                      loading: "Deleting avatar...",
                      success: "Avatar deleted successfully",
                      error: "Error deleting avatar",
                    });
                    dispatch(updateUser({ avatar: null, id: user._id }));
                  }}
                >
                  Remove
                </Button>
              </div>
              <p className="fs-14px text-muted m-0">
                Please, maintain your profile updated
              </p>
            </div>
          </div>
        </section>

        <Row className="g-4 my-4">
          <Col md="6">
            <p className="mb-2">Full Name</p>
            <Input
              control={control}
              name="name"
              type="text"
              // placeholder="Full Name"
              TextInput={CustomTextField}
            />
          </Col>
          <Col md="6">
            <p className="mb-2">Country</p>
            <Input
              fullWidth
              id="country"
              select
              label=" "
              TextInput={CustomTextField}
              control={control}
              name="country"
            >
              {countries.map((country, index) => {
                return (
                  <MenuItem value={country.text} key={index}>
                    <div
                      className={`fi fi-${country.id} me-2 rounded-circle border w-20 h-20`}
                    ></div>
                    {country.text}
                  </MenuItem>
                );
              })}
            </Input>
          </Col>
          <Col md="6">
            <p className="mb-2">Email</p>
            <Input
              TextInput={CustomTextField}
              fullWidth
              type={"email"}
              label=" "
              id="email"
              name="email"
              value={user.email}
              readOnly
            />
          </Col>
          <Col md="6">
            <p className="fs-14px mb-2">Contact Number</p>
            <Input
              fullWidth
              label=" "
              InputProps={{
                inputComponent: TextMaskCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {callingCode ? `+${callingCode}` : ""}
                  </InputAdornment>
                ),
              }}
              TextInput={CustomTextField}
              control={control}
              {...register("phone", { required: true })}
            />
          </Col>
          <Col md="6">
            <p className="mb-2">Date of Birth</p>
            <Row>
              <Col xs="4">
                <Input
                  fullWidth
                  id="day"
                  select
                  label=" "
                  TextInput={CustomTextField}
                  control={control}
                  name="day"
                >
                  {days.map((day, index) => {
                    return (
                      <MenuItem key={index} value={day}>
                        {day}
                      </MenuItem>
                    );
                  })}
                </Input>
              </Col>
              <Col xs="4">
                <Input
                  fullWidth
                  id="month"
                  select
                  label=" "
                  TextInput={CustomTextField}
                  control={control}
                  name="month"
                >
                  {month.map((month, index) => {
                    return (
                      <MenuItem key={index} value={index + 1}>
                        {month}
                      </MenuItem>
                    );
                  })}
                </Input>
              </Col>
              <Col xs="4">
                <Input
                  fullWidth
                  id="year"
                  label=" "
                  TextInput={CustomTextField}
                  control={control}
                  name="year"
                  select
                >
                  {years.map((day, index) => {
                    return (
                      <MenuItem key={index} value={day}>
                        {day}
                      </MenuItem>
                    );
                  })}
                </Input>
              </Col>
            </Row>
          </Col>
          {/* <Col md="6">
            <p className="mb-2">Metamask account</p>
            <Row className="g-2">
              <Col xs="9">
                <Input
                  fullWidth
                  type={"email"}
                  label=" "
                  id="metataskAccount"
                  placeholder="email of your metamask account"
                />
              </Col>
              <Col>
                <Button variant="secondary" className="w-100 h-100 rounded-0">
                  Link
                </Button>
              </Col>
              <Col xs="12">
                <p className="fs-14px">
                  *You need it to collect NFTs bottles for your cellar, if you
                  don´t have one, please create a new one for free{" "}
                  <Link to="/">here</Link>
                </p>
              </Col>
            </Row>
          </Col> */}
          <Col xs="12" className="mt-4">
            <p className="fs-14px">
              You must fill in all fields to be able to continue
            </p>
          </Col>
          <Col xs="12">
            <Button
              variant="success"
              type="submit"
              className="px-4 rounded-1 me-3"
              disabled={isLoading}
            >
              Save
            </Button>
            <Button variant="outline-secondary" className="px-4  rounded-1">
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};
