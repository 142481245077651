import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import locationIcon from "assets/images/icons/location.png";
import worldIcon from "assets/images/icons/world.svg";
import { MoneyButton } from "Components/MoneyButton";
import Modal from "react-bootstrap/Modal";
import { TextIconButton } from "Components/TextIconButton";
import { GrClose } from "react-icons/gr";
import Button from "react-bootstrap/Button";
import { addVisit } from "store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import SweetAlert from "react-bootstrap-sweetalert";
import toast from "react-hot-toast";
import ImageLightBox from "utils/LightBox";
import StreamPopup from "utils/StreamPopup";
export const HomeSection = ({ winery = {} }) => {
  const [showStartPopup, setShowStartPopup] = useState(false);
  const handleStartPopup = () => setShowStartPopup(!showStartPopup);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [wineryLinks, setWineryLinks] = useState({});
  const [showLightBox, setShowLightBox] = useState({
    open: false,
    index: 0,
  });
  useEffect(() => {
    if (!showStartPopup) {
      setWineryLinks({});
    }
  }, [showStartPopup]);
  return (
    <section className="bg-white rounded-16 p-4 shadow-custom heroSection bottomSpace">
      <Row className="my-4 g-0 justify-content-between position-relative">
        <img
          src={winery.logo}
          alt="Brand Img"
          width={"100%"}
          height={"auto"}
          className="img-fluid obj-fit-cover obj-position-center mw-150px position-absolute top-0 end-0"
        />
        <Col lg="6">
          <h2 className="mb-5 col-6">{winery.name}</h2>
          <div className="d-flex flex-column gap-3 mb-4">
            {winery.region && (
              <h5 className="fw-normal">
                <img
                  src={locationIcon}
                  loading="lazy"
                  alt="Location"
                  width={"28px"}
                  height={"28px"}
                  className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
                />
                {winery.region}
              </h5>
            )}
            {winery.country && (
              <h5 className="fw-normal">
                <img
                  src={worldIcon}
                  loading="lazy"
                  alt="world"
                  width={"28px"}
                  height={"28px"}
                  className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
                />
                {winery.country}
              </h5>
            )}

            {winery.website && (
              <h5 className="fw-normal">
                <img
                  src={worldIcon}
                  loading="lazy"
                  alt="world"
                  width={"28px"}
                  height={"28px"}
                  className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
                />
                {winery.website}
              </h5>
            )}
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: winery.description }}
            className="mb-5"
          ></div>
          {!winery.comingSoon ? (
            <MoneyButton
              variant="secondary"
              number={winery.credits}
              value="Access now"
              className="rounded-pill"
              onClick={() => {
                if (user.visits.includes(winery._id)) {
                  const id = toast.loading("Accessing...");
                  dispatch(
                    addVisit(winery._id, {
                      onSuccess: (response) => {
                        toast.dismiss(id);
                        setWineryLinks(response.data.winery);
                        handleStartPopup();
                      },
                      onFailure: () => {
                        toast.dismiss(id);
                      },
                    })
                  );
                } else {
                  confirmAlert({
                    title: "Are you sure you want to access this winery?",
                    customUI: ({ onClose }) => (
                      <SweetAlert
                        onConfirm={() => {
                          const id = toast.loading("Accessing...");
                          onClose();
                          dispatch(
                            addVisit(winery._id, {
                              onSuccess: (response) => {
                                toast.dismiss(id);
                                setWineryLinks(response.data.winery);
                                handleStartPopup();
                              },
                              onFailure: () => {
                                toast.dismiss(id);
                              },
                            })
                          );
                        }}
                        info
                        onCancel={onClose}
                        confirmBtnText="Access"
                        cancelBtnText="Cancel"
                        title="Are you sure you want to access this winery?"
                        confirmBtnBsStyle="secondary"
                        cancelBtnBsStyle="default"
                        showCancel
                      >
                        By clicking "Yes" you will be able to access this winery
                        and {winery.credits} credits will be deducted.
                      </SweetAlert>
                    ),
                  });
                }
              }}
            />
          ) : (
            <div
              className="rounded-pill d-inline-block"
              onClick={() => {
                toast.error("Winery is coming soon...");
              }}
            >
              <Button
                variant="dark"
                className="rounded-pill px-4 py-2"
                style={{ backgroundColor: "#6c757d", borderColor: "#6c757d" }}
                disabled
              >
                <div className="px-3">Coming Soon...</div>
              </Button>
            </div>
          )}
        </Col>
        <Col lg="6">
          <div className="p-3">
            <img
              src={winery.map}
              alt="map img"
              width={"100%"}
              height="auto"
              className="img-fluid obj-fit-cover obj-position-center"
            />
          </div>
        </Col>
      </Row>
      {winery.images?.length > 0 && (
        <Row xs="4" className="g-3 profileImgSection">
          <ImageLightBox
            id={winery._id}
            images={winery.images}
            pos={0}
            open={showLightBox.open}
            position={showLightBox.index}
            onClose={() =>
              setShowLightBox({
                open: false,
                index: 0,
              })
            }
          />
          {winery.images?.map((profileImg, index) => {
            return (
              <div key={index}>
                <img
                  src={profileImg}
                  alt="Profile Img"
                  width={"100%"}
                  height="auto"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setShowLightBox({
                      open: true,
                      index,
                    })
                  }
                  className="img-fluid obj-fit-cover obj-position-center rounded-12"
                />
              </div>
            );
          })}
        </Row>
      )}
      <Modal
        show={showStartPopup}
        onHide={handleStartPopup}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <Button
            type="button"
            variant="link"
            className="bg-secondary-10 text-secondary px-3 py-2 rounded-2 mt-2 position-absolute top-0 end-0 me-3 mt-3"
            onClick={handleStartPopup}
          >
            <GrClose />
          </Button>
          <section className="rounded-16 p-4 shadow-custom bg-pattren-img">
            <Row className="my-4 g-0">
              <Col xs="12">
                <div className="d-flex flex-wrap flex-column-reverse flex-sm-row justify-content-between">
                  <h2 className="mb-4">{winery.name}</h2>
                  <div className="mx-auto me-0">
                    <img
                      src={winery.logo}
                      alt="Logo"
                      loading="lazy"
                      width={"100%"}
                      height="auto"
                      className="img-fluid obj-fit-cover obj-position-center mw-150px"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6" xl="7">
                <div className="mb-5">
                  {winery.region && (
                    <h5 className="mb-4 fw-normal">
                      <img
                        src={locationIcon}
                        loading="lazy"
                        alt="Location"
                        width={"28px"}
                        height={"28px"}
                        className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
                      />
                      {winery.region}
                    </h5>
                  )}
                  {winery.country && (
                    <h5 className="fw-normal">
                      <img
                        src={worldIcon}
                        loading="lazy"
                        alt="world"
                        width={"28px"}
                        height={"28px"}
                        className="me-2 ratio-1x1 img-fluid obj-fit-cover obj-position-center"
                      />
                      {winery.country}
                    </h5>
                  )}
                </div>
                <p>
                  Great! Now select one of the two options to start your visit:
                </p>
                <ul>
                  <li className="lh-lg">
                    “Start to Streaming”, no needed to download or high graphics
                    settings to reproduce the 3D visit in your PC, we proccess
                    all from our servers with our best streaming technology.
                  </li>
                  <li className="lh-lg">
                    “Download to your PC” to reproduce directly in your PC, you
                    should have a powerfull graphic card (recommended NVIDIA GTX
                    1060 / AMD Radeon Vega 56 or greater). Download and execute,
                    with this option you could visualize with <b>VR glasses</b>.
                  </li>
                </ul>
              </Col>
              <Col
                lg="6"
                style={{
                  marginTop: "-30px",
                }}
                className="p-4"
                xl="5"
              >
                <img
                  src={winery.map}
                  alt="MAP img"
                  className="w-100 h-auto img-fluid"
                />
              </Col>
            </Row>
            <Row className="g-4 justify-content-around">
              <Col xs="10" sm="8" md="6" lg="4" xl="4">
                <div>
                  <div className="text-center mb-2">
                    <TextIconButton
                      url="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(wineryLinks.streaming_link);
                      }}
                      text="Start to streaming"
                    />
                  </div>
                  <p>
                    You´ll have 60 minutes to visit the winery, you could access
                    as many times you want until the time end.
                  </p>
                </div>
              </Col>

              <Col xs="10" sm="8" md="6" lg="4" xl="4">
                <div>
                  <div className="text-center mb-2">
                    <TextIconButton
                      url="/"
                      onClick={() => {
                        window.open(wineryLinks.download_link, "_blank");
                      }}
                      text="Download to your PC"
                      className="bg-success"
                    />
                  </div>
                  <p>
                    Download to your PC for free, visit anytime without time
                    restrictions and visualize in VR glasses.
                  </p>
                </div>
              </Col>
            </Row>
          </section>
        </Modal.Body>
      </Modal>
      {wineryLinks.streaming_link && (
        <StreamPopup
          handleClose={(e) => {
            setId("");
            e.preventDefault();
            setTimeout(() => {
              window.location.reload();
            }, 10);
          }}
          id={id}
        />
      )}
    </section>
  );
};
