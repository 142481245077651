import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import useScrollPosition from "@react-hook/window-scroll";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import SecondWinery from "assets/images/logo/secondwinery.png";
import SecondWineryDark from "assets/images/logo/secondwinery-dark.png";
import NotficationIcon from "assets/images/icons/notification-bing.svg";
import MoneyIcon from "assets/images/icons/moneys.svg";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import swLogo from "assets/images/logo/sw-logo.png";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
} from "@mui/material";
import { logout } from "store/auth/actions";
import toast from "react-hot-toast";
import API from "helpers/api";
import useAxiosFetch from "hooks/useAxiosFetch";
import { DateTime } from "luxon";

export const WineryHeader = () => {
  const scrollY = useScrollPosition(60);
  const navigate = useNavigate();
  const [navbarBG, setNavbarBG] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const { user } = useSelector((state) => state.auth);
  const {
    data: { notifications } = {},
    updateData,
    mutate: getNotifications,
  } = useAxiosFetch("/notifications", { method: "GET", lazy: true });
  useEffect(() => {
    setNavbarBG(scrollY > 60);
    if (scrollY > 60) setExpanded(false);
  }, [scrollY]);
  const handleClose = () => setAnchorEl(null);
  const dispatch = useDispatch();
  const unreadNotificationsLength =
    (notifications || user.notifications)?.filter(
      (notification) => !notification.isRead
    ).length || 0;
  return (
    <Navbar
      collapseonselects="true"
      expand="xl"
      expanded={expanded}
      bg="transaprent"
      variant="light"
      fixed="top"
      className={navbarBG ? "navbarbg py-4" : "py-4"}
    >
      <Container>
        <Button
          variant="secondary"
          className="rounded-circle w-40 ratio-1x1 p-0 d-flex justify-content-center align-items-center me-3"
          onClick={() => {
            navigate(-1);
          }}
        >
          <BiArrowBack />
        </Button>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={navbarBG ? SecondWineryDark : SecondWinery}
              alt="Logo"
              loading="lazy"
              className="img-fluid mw-200px"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="mainNavbar"
          onClick={() => {
            setExpanded(!expanded);
          }}
          className="h2 p-0 lh-1 shadow-none border-0 m-0"
        >
          {expanded ? <FiMenu /> : <AiOutlineClose />}
        </Navbar.Toggle>
        <Navbar.Collapse id="mainNavbar">
          <Nav className="flex-row ms-auto gap-3 gap-lg-4 justify-content-end">
            <a
              href="/credits"
              className="bg-secondary rounded-pill d-flex gap-3 align-items-center h-40 px-4 text-decoration-none"
            >
              <img src={MoneyIcon} alt="Money Icon" width={"20px"} />
              <span className="fs-14px text-white">{user.credits}</span>
            </a>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                getNotifications();
              }}
              className="bg-white w-40 h-40 rounded-circle d-flex justify-content-center align-items-center position-relative cursor-pointer"
            >
              <img
                src={NotficationIcon}
                alt="Notifvation Icon"
                width={"24px"}
                height="auto"
                className="img-fluid obj-fit-cover obj-position-center "
              />
              <div
                className="position-absolute text-white bg-secondary rounded-circle ratio-1x1 d-flex justify-content-center align-content-center fs-10px"
                style={{
                  padding: ".25rem",
                  top: "3px",
                  right: "5px",
                  width: 20,
                  height: 20,
                  lineHeight: `12.5px`,
                }}
              >
                {unreadNotificationsLength < 100
                  ? unreadNotificationsLength < 10
                    ? `0${unreadNotificationsLength}`
                    : unreadNotificationsLength
                  : "99+"}
              </div>
            </IconButton>
            <Popover
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              PaperProps={{
                "aria-labelledby": "basic-button",
                style: {
                  width: 350,
                  maxHeight: 450,
                },
              }}
            >
              <List className="py-0 h-100">
                <ListSubheader className="shadow-sm mb-3 py-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="h6 fw-bold mb-0">Notifications</span>
                    <a
                      onClick={async (e) => {
                        e.preventDefault();
                        if (notifications?.find((n) => !n.isRead)) {
                          await updateData(
                            () => API.post("/notifications/read"),
                            {
                              onSuccess: () => {
                                toast.success("Notifications marked as read");
                              },
                            }
                          );
                        } else {
                          toast("You are all set!", {
                            icon: "👏",
                            style: {
                              borderRadius: "10px",
                              background: "#333",
                              color: "#fff",
                            },
                          });
                        }
                      }}
                      href="/"
                      className="fs-12px text-decoration-none text-secondary d-inline-flex align-items-center"
                    >
                      <DoneAllIcon fontSize="small" className="me-2" /> Mark all
                      as read
                    </a>
                  </div>
                </ListSubheader>
                {notifications?.map((notification, index) => (
                  <ListItemButton
                    className="mx-3 mb-2 rounded-3"
                    dense
                    sx={{
                      backgroundColor: !notification?.isRead
                        ? "rgba(0,0,0,0.05)"
                        : "transparent",
                      "& .span": {
                        color: !notification?.isRead ? "primary.main" : "gray",
                      },
                      "&:hover .span": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: 20,
                      }}
                    >
                      <Box
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor:
                            notification?.isRead === false
                              ? "primary.main"
                              : "primary.light",
                        }}
                      ></Box>
                    </ListItemIcon>
                    <ListItem
                      className="ps-0"
                      dense
                      key={index}
                      onClick={handleClose}
                    >
                      <ListItemText
                        primary={
                          <span className="span d-inline-block pe-4">
                            {notification?.notification.body}
                          </span>
                        }
                        secondary={
                          <div className="d-flex justify-content-between">
                            <span className="fs-12px">
                              {DateTime.fromISO(
                                notification?.notification.createdAt
                              ).toFormat("LLL dd, yyyy hh:mm a")}
                            </span>
                          </div>
                        }
                      />
                    </ListItem>
                    <ListItemSecondaryAction>
                      <Avatar
                        alt="Remy Sharp"
                        className="p-1 border bg-white"
                        src={swLogo}
                      />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ))}
                <ListSubheader
                  sx={{
                    top: "auto",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      cursor: "pointer",
                    },
                  }}
                  className="shadow-sm bottom-0 mt-3"
                  onClick={() => {
                    if (notifications?.length) {
                      updateData(() => API.delete("/notifications"), {
                        onSuccess: () => {
                          toast.success("Notifications Deleted");
                        },
                      });
                    } else {
                      toast("You are all set!", {
                        icon: "👏",
                        style: {
                          borderRadius: "10px",
                          background: "#333",
                          color: "#fff",
                        },
                      });
                    }
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <DeleteIcon fontSize="small" className="me-2" />
                    <span className="fs-12px">Clear all</span>
                  </div>
                </ListSubheader>
              </List>
            </Popover>
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="bg-transparent border-0 shadow-none p-0"
              >
                <Avatar
                  src={user?.avatar}
                  alt="profile Img"
                  className="img-fluid obj-fit-cover obj-position-center ratio-1x1 w-40 h-40 rounded-circle border border-white border-3"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div">
                  <Link to="/account" className="d-block text-decoration-none">
                    User Profle
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(logout());
                  }}
                  as="div"
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
