import React from "react";
import img1 from "assets/images/cards/instruction-card-1.png";
import img2 from "assets/images/cards/instruction-card-2.png";
import img3 from "assets/images/cards/instruction-card-3.png";
import { Button } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
const Instructions = () => {
  const [checked, setChecked] = useState(false);
  return (
    <div className="min-vh-100">
      <h3 className="mb-5 text-center">
        How to create & connect your wallet to marketplace
      </h3>

      <div className="p-md-4 bg-white rounded-3">
        <div>
          <div className="bg-dark mb-4 rounded-4 text-center text-white p-3">
            <p
              style={{
                fontSize: "0.95rem",
              }}
            >
              NFTs (non-fungible tokens) are unique cryptographic tokens that
              are stored on a blockchain, meaning that they cannot be replicated
            </p>
            <p
              style={{
                fontSize: "0.95rem",
              }}
            >
              Through Second Winery you can collect NFTs from your favourite
              wineries. Second Winery offers a complete marketplace for wine
              lovers.
            </p>
            <p
              style={{
                fontSize: "0.95rem",
              }}
            >
              Every NFT Is unique, original and promoted by the wherles in our
              marketplace.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="bg-light px-3 py-4 rounded-4 h-100 shadow-sm">
              <div className="mb-3 d-flex">
                <div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      fontSize: "0.9rem",
                    }}
                    className="d-flex me-2 text-white justify-content-center align-items-center rounded-pill bg-secondary"
                  >
                    1
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  You need a wallet to buy and store your digital assets (NFTs).
                  <br />
                  Click to <b>CONNECT WALLET</b>".
                </p>
              </div>
              <div className="text-center">
                <img
                  src={img1}
                  alt=""
                  className="img-fluid shadow-sm rounded-3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-light px-3 py-4 rounded-4 h-100 shadow-sm">
              <div className="mb-3 d-flex">
                <div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      fontSize: "0.9rem",
                    }}
                    className="d-flex me-2 text-white justify-content-center align-items-center rounded-pill bg-secondary"
                  >
                    2
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Then select one of following wallets, we recommend{" "}
                  <b>Metamask</b>.
                </p>
              </div>
              <div className="text-center">
                <img
                  src={img2}
                  alt=""
                  className="img-fluid shadow-sm rounded-3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-light px-3 py-4 rounded-4 h-100 shadow-sm">
              <div className="mb-3 d-flex">
                <div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      fontSize: "0.9rem",
                    }}
                    className="d-flex me-2 text-white justify-content-center align-items-center rounded-pill bg-secondary"
                  >
                    3
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  If you don't have one, we will ask you to register for a new
                  one, in the case of Metamask, download it and follow the
                  instructions... in 1 min you will have it ready.
                </p>
              </div>
              <div className="text-center">
                <img
                  src={img3}
                  alt=""
                  className="img-fluid shadow-sm rounded-3"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div>
              <FormControlLabel
                control={<Checkbox color="primary" checked={checked} onChange={(e)=> {
                  setChecked(e.target.checked)
                }} />}
                label="Don't show this again"
              />
            </div>
            <div>
              <Button onClick={()=> {
                localStorage.setItem("showInstructions", checked);
                window.open("https://nft.secondwinery.com", "_blank");
              }} type="submit" variant="success" className="px-5">
                Access Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
