import React from "react";
import { TextIconButton } from "Components/TextIconButton";
export const Product = ({ ...product }) => {
  return (
    <div className={`bg-white p-3 rounded-8 mx-auto`}>
      <div className="d-flex align-items-center justify-content-center mb-4">
        <img
          src={product.img}
          alt="Product Img"
          loading="lazy"
          width={"100%"}
          height="100%"
          style={{aspectRatio: "3/2", objectFit: "contain", objectPosition: "center"}}
          className="img-fluid obj-fit-cover obj-position-center"
        />
      </div>
      <h6 className="mb-3">{product.title}</h6>
      <TextIconButton url={product.url} text="See Information" />
    </div>
  );
};
